import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Profile from '../views/userprofile/profile.vue'
import Register from '../views/Register.vue'
import Dashboard from '../views/dashboard/Dashboard.vue'
import Destination from '../views/destination/Destination.vue'
import Hotelidinfo from '../views/destination/Hotelidinfo.vue'
import Hotelnameinfo from '../views/destination/Hotelnameinfo.vue'
import Hoteldetials from '../views/destination/Hoteldetials.vue'
import Hotelinfo from '../views/destination/Hotelinfo.vue'
import Map from '../views/destination/Map.vue'
import DestinationOsm from '../views/destination/DestinationOsm.vue'
import Destinationcustom from '../views/destination/Destinationcustom.vue'
import CustomMap from '../views/destination/Custommap.vue'
import Test from '../views/destination/Test.vue'
import vuecode from '../views/destination/vuecode.vue'
import Api from '../views/api/Api.vue'
import auth from '../auth/auth'
import Error from '../views/error/Error.vue'
import HotelList from '../views/error/HotelList.vue'
import AllClientLogs from '../views/error/AllClientLogs.vue'
import ClientLogs from '../views/error/AdminError.vue'
import Boundary from '../views/boundary/Boundary.vue'
import ClientPlan from '../views/setting/ClientPlan.vue'
import Adminsetting from '../views/setting/adminsetting.vue'
import Report from '../views/report/Report.vue'
// import ClientCityList from '../views/report/ClientCityList.vue'
import UserDetails from '../views/userprofile/userDetails.vue'
import AgentSettings from '../views/agent/AgentSettings.vue'
// import AgentList from '../views/agent/AgentList.vue'
// import AgentError from '../views/error/AgentError.vue'
import AgentAssign from '../views/agent/AgentAssign.vue'
import AgentCsv from '../views/agent/AgentCsv.vue'
import VerifyPlotting from '../views/boundary/VerifyPlotting.vue'
import ViewCustomPolygon from '../views/dashboard/ViewCustomPolygon.vue'
import ViewPolygon from '../views/googlemap/ViewPolygon.vue'
import CheckPolygon from '../views/googlemap/CheckPolygon.vue'
// import ClientHits from '../views/dashboard/ViewClientHits.vue'
// import Xmlout from '../views/error/Xmlout.vue'
import ClientCustomList from '../views/error/ClientsCustomList.vue'
// import XmloutLogs from '../views/error/XmloutLogs.vue'
import AdminSaveResponse from '../views/boundary/AdminSaveResponse.vue'


Vue.use(VueRouter)
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    linkActiveClass: 'menuActive',
    linkExactActiveClass: 'border-indigo-700',
    // base: '/',
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes: [
        {
          path: '*',
          redirect: '/log', // Redirect all unknown routes to the login page
        },
        {
            path: '/log',
            name: 'login',
            component: Login,
            meta: {
              requiresAuth: false,
            }
        },
        {
          path: '/metrics',
          meta: {
            requiresAuth: false,
          }
        },
        {
            path: '/agentsettings',
            name: 'agentsettings',
            component: AgentSettings,
            meta: {
              requiresAuth: true,
            }
      
        },
        {
          path: '/updateagentdata',
          name: 'updateagentdata',
          component: AgentCsv,
          meta: {
            requiresAuth: true,
          }
    
      },
        // {
        //   path: '/agentlist',
        //   name: 'agentlist',
        //   component: AgentList,
        //   meta: {
        //     requiresAuth: true,
        //   }
        // },
        {
          path: '/agentassign',
          name: 'agentassign',
          component: AgentAssign,
          meta: {
            requiresAuth: true,
          }
        },
        // {
        //   path: '/clientcitylist/:user/:id',
        //   name: 'clientcitylist',
        //   component: ClientCityList,
        //   meta: {
        //     requiresAuth: true,
        //   }
        // },
        {
          path: '/clientcustomlist/:user/:id',
          name: 'clientcustomlist',
          component: ClientCustomList,
          meta: {
            requiresAuth: true,
          }
        },
        {
            path: '/register',
            name: 'register',
            component: Register,
            meta: {
              requiresAuth: false,

            }
      
        },
        {
            path: '/dashboard',
            name: 'dashboard',
            component: Dashboard,
            meta: {
              requiresAuth: true
            }
      
        },
        {
          path: '/clientsearchlogs',
          name: 'clientsearchlogs',
          component: AllClientLogs,
          meta: {
            requiresAuth: true
          }
    
        },
        {
          path: '/hotellist/:location/:country/:user/:username/:state/:supplier',
          name: 'hotellist',
          component: HotelList,
          meta: {
            requiresAuth: true
          }
    
        },
        {
            path: '/api',
            name: 'api',
            component: Api,
            meta: {
              requiresAuth: true
            }
      
        },
        {
            path: '/destination',
            name: 'destination',
            component: Destination,
            meta: {
              requiresAuth: true
            }
      
        },
        {
          path: '/custommap/:city/:country/:state/:index/:source/:userid',
          name: 'custommap',
          component: CustomMap,
          meta: {
            requiresAuth: true
          }
    
        },
        {
          path: '/clientplan/:id',
          name: 'clientplan',
          component: ClientPlan,
          meta: {
            requiresAuth: true
          }
    
      },
        {
          path: '/supplierinfo',
          name: 'supplierinfo',
          component: Hotelidinfo,
          meta: {
            requiresAuth: true
          }
    
      },
      {
        path: '/hotelnameinfo',
        name: 'hotelnameinfo',
        component: Hotelnameinfo,
        meta: {
          requiresAuth: true
        }
  
    },
    {
      path: '/hotelinformation',
      name: 'hotelinformation',
      component: Hotelinfo,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/hoteldetails',
      name: 'hoteldetails',
      component: Hoteldetials,
      meta: {
        requiresAuth: true
      }

    },
        {
          path: '/map',
          name: 'map',
          component: Map,
          meta: {
            requiresAuth: true
          }
    
        },
        {
            path: '/destination_osm',
            name: 'destination_osm',
            component: DestinationOsm,
            meta: {
              requiresAuth: true
            }
      
        },
        {
          path: '/destinationcustom/:city/:state/:country/:supplier/:username/:source/:userid/flag',
          name: 'destinationcustom',
          component: Destinationcustom,
          meta: {
            requiresAuth: true
          }
    
      },
        {
            path: '/test',
            name: 'Test',
            component: Test,
            meta: {
              requiresAuth: true
            }
      
        },
        {
            path: '/vuecode',
            name: 'vuecode',
            component: vuecode,
            meta: {
              requiresAuth: true
            }
      
        },
        {
            path: '/userprofile',
            name: 'userprofile',
            component: Profile,
            meta: {
              requiresAuth: true
            }
      
        },
        {
          path: '/error',
          name: 'error',
          component: Error,
          meta: {
            requiresAuth: true
          }
    
        },
        {
          path: '/clientlogs/:user/:id',
          name: 'clientlogs',
          component: ClientLogs,
          meta: {
            requiresAuth: true
          }
    
        },
        // {
        //   path: '/xmlout',
        //   name: 'xmlout',
        //   component: Xmlout,
        //   meta: {
        //     requiresAuth: true
        //   }
    
        // },
        // {
        //   path: '/xmloutlogs/:id',
        //   name: 'xmloutlogs',
        //   component: XmloutLogs,
        //   meta: {
        //     requiresAuth: true
        //   }
    
        // },
        {
          path: '/viewpolygon/:location/:city/:country/:datetime/:info',
          name: 'viewpolygon',
          component: ViewPolygon,
          meta: {
            requiresAuth: true
          }
    
        },
        {
          path: '/checkpolygon/:location/:city/:country/:datetime',
          name: 'checkpolygon',
          component: CheckPolygon,
          meta: {
            requiresAuth: true
          }
    
        },
        {
          path: '/setting',
          name: 'setting',
          component: Adminsetting,
          meta: {
            requiresAuth: true
          }
        
        },
        {
          path: '/boundary/:location/:state/:country/:index',
          name: 'boundary',
          component: Boundary,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/savepolygonresponse',
          name: 'adminsaveresponse',
          component: AdminSaveResponse,
          meta: {
            requiresAuth: true
          }
        
        },
        {
          path: '/userDetails/:user/:name',
          name: 'userDetails',
          component: UserDetails,
          meta: {
            requiresAuth: true
          }
        
          },
        {
          path: '/report/:location/:country/:user/:time/:date',
          name: 'report',
          component: Report,
          meta: {
            requiresAuth: true
          }
    
      },
      {
        path: '/viewcustompolygon',
        name: 'viewcustompolygon',
        component: ViewCustomPolygon,
        meta: {
          requiresAuth: true
        }
      
        },
      // {
      //   path: '/plotting/:city/:country/:code/:supplier',
      //   name: 'plotting',
      //   component: Plotting,
      //   meta: {
      //     requiresAuth: true
      //   }
      // }  
      {
        path: '/verifyplotting/:name/:city/:country',
        name: 'verifyplotting',
        component: VerifyPlotting,
        meta: {
          requiresAuth: true
        }
      }  
    ],

})


router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      // console.log("token", localStorage.getItem('token'))
      // console.log("auth", auth)
      
      // console.log("auth.isAuthenticated()", auth.isAuthenticated())
      if (!auth.isAuthenticated()) {
        // if(to.meta.isAdmin){

        // }
        next({
          name: 'login',
          query: { redirect: to.fullPath }
        })
      next()
      } else {
        next()
      }
    } else {
      next()
    }
  })
  

export default router
  