<template>
    <div>
        <div class="map__section">
            <div class="map__filter" :class="[showfilter ? 'collapse__menu' : '']">
                <h3 class="headingMain">Destination </h3>
                <p class="subheadingMain">Enter location details here!</p>
                <hr>
                <div class="form__map">

                    <div class="form__group dropdown">
    <div class="input-field">
        <label class="boxBread">Select Suppliers</label>
        <div class="dropdown" :class="{ open: isDropdownOpen }">
            <div class="dropdown-toggle" @click="toggleDropdown">
                <span v-if="selectedSupplier.length">
                    {{ selectedSupplier.join(', ') }}
                </span>
                <span v-else>Select Suppliers<i class="arrow"></i></span>
            </div>
            <ul class="dropdown-menu" v-if="isDropdownOpen">
                <!-- Select All Checkbox -->
                <li class="newator">
                    <input type="checkbox" class="checksocks" id="selectAll"
                        :checked="isAllSelected" @change="toggleSelectAll" />
                    <p class="basicname" for="selectAll">Select All</p>
                </li>
                <!-- Individual Country Checkboxes -->
                <li v-for="supplier in SupplierList" :key="supplier" class="newator">
                    <input type="checkbox" class="checksocks" :id="supplier" :value="supplier"
                        v-model="selectedSupplier" @change="getcustompolygonlist" />
                    <p class="basicname" :for="supplier">{{ supplier }}</p>
                </li>
            </ul>
        </div>
    </div>
</div>





                    <div class="form__group">
                        <!-- <div>
                            <label class="margBase">
                                <input type="radio" class="h-auto" v-model="selectedOption" value="Suppliers Data" /><b class="checkmark">Suppliers Data</b>
                            </label>
                        </div>
                        <div>
                            <label class="margBase">
                                <input type="radio" class="h-auto" v-model="selectedOption" value="Hotels Master" /><b class="checkmark">Hotels Master (Otrams)</b>
                            </label>
                        </div> -->
                        <!--<div class="input-field" >
                                <b-form-select v-model="selectedSupplier" class="form-control" :options="SupplierList"></b-form-select>
                            <label>Select Supplier</label>
                        </div> -->
                        <div class="input-field">
                            <!-- <b-form-input type="text" v-model="selectedCountry" @keyup.enter="applymapfilter" required spellcheck="false"
                                autocomplete="off"></b-form-input> -->
                            <b-form-select v-model="selectedCountry" class="form-control" :options="countryData"
                                @change="getcustompolygonlist"></b-form-select>
                            <label>Select Country</label>
                        </div>
                    </div>
                    <div class="form__group">
                        <div class="input-field">
                            <b-form-input type="text" @input="updateOptions" v-model="selectedValue"
                                @keyup.enter="applymapfilter" required spellcheck="false" autocomplete="off"
                                list="optionsList"></b-form-input>
                            <!-- <b-form-datalist :options="cityData" v-if="cityData.length > 0"></b-form-datalist> -->
                            <b-form-datalist id="optionsList" v-if="showDatalist" :options="cityData"></b-form-datalist>
                            <!-- <b-form-select v-model="selectedValue" class="form-control" :options="cityData"></b-form-select> -->
                            <label>Enter City</label>
                        </div>
                    </div>
                    <div class="form__group d-none">
                        <div class="input-field">
                            <b-form-input type="text" @input="updateOptions" v-model="selectedValueKM"
                                @keyup.enter="applymapfilter" required spellcheck="false" autocomplete="off"
                                list="optionsList"></b-form-input>
                            <!-- <b-form-datalist :options="cityData" v-if="cityData.length > 0"></b-form-datalist> -->
                            <!-- <b-form-datalist id="optionsList" v-if="showDatalist" :options="cityData" ></b-form-datalist> -->
                            <!-- <b-form-select v-model="selectedValue" class="form-control" :options="cityData"></b-form-select> -->
                            <label>Enter KM</label>
                        </div>
                    </div>
                    <div class="form__group">
                        <div class="input-field">
                            <b-form-input type="text" @input="updateOptions" v-model="selectedstate"
                                @keyup.enter="applymapfilter" required spellcheck="false" autocomplete="off"
                                list="optionsList"></b-form-input>
                            <!-- <b-form-datalist :options="cityData" v-if="cityData.length > 0"></b-form-datalist> -->
                            <!-- <b-form-datalist id="optionsList" v-if="showDatalist" :options="cityData" ></b-form-datalist> -->
                            <!-- <b-form-select v-model="selectedValue" class="form-control" :options="cityData"></b-form-select> -->
                            <label>Enter State</label>
                        </div>
                    </div>
                </div>
                <label class="margBase">
                    <input class="checkHeight" type="checkbox" :value="true" v-model="isEditable"><b
                        class="checkmark">Edit Map Co-ordinates</b>
                </label>
                <div class="navigation__filter text-end">
                    <b-button @click="applymapfilter">Show Hotels &nbsp;<b-icon icon="arrow-right"></b-icon></b-button>
                </div>
                <div class="close__map__filter" @click="showfilter = !showfilter"><b-icon icon="list"
                        class="trans90"></b-icon></div>
            </div>
            <div class="map__filter2" :class="[showfilter2 ? 'collapse__menu2' : '']">
                <h3><b-icon icon="person-bounding-box"></b-icon> Destination <span class="close__icon"
                        @click="closelist"><b-icon icon="x-square"></b-icon></span></h3>
                <hr>
                <div class="text-center mar_top" v-if="showloader">
                    <b-spinner variant="primary" :small="true" type="grow"></b-spinner> &nbsp;
                    <b-spinner variant="secondary" :small="true" type="grow"></b-spinner> &nbsp;
                    <b-spinner variant="danger" :small="true" type="grow"></b-spinner> &nbsp;
                    <b-spinner variant="warning" :small="true" type="grow"></b-spinner> &nbsp;
                    <b-spinner variant="success" :small="true" type="grow"></b-spinner>
                </div>
                <div v-else>
                    <b-button variant="primary" class="destination_save" @click="mapUpdated"
                        v-if="isEditable">Save</b-button>
                    <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                            <b-button block v-b-toggle.accordion-4 variant="Success"
                                class="w-100 d-flex justify-content-between bg-under bg-info">
                                <h4 class="text-white mb-0"><b-icon icon="info-circle" class="text-white"></b-icon><span
                                        class="infogap">Info Panel</span></h4>
                                <b-icon id="popover-target-3" icon="exclamation-circle"></b-icon>
                                <b-popover target="popover-target-3" triggers="hover" placement="top">
                                    <!-- <template #title>Popover Title</template> -->
                                    INFO FOR COUNT OF SUPPLIERS INSIDE(<span class="green">GREEN</span>) AND SUPPLIERS
                                    OUTSIDE(<span class="red">RED</span>)
                                    <b class="city-text">{{ selectedValue }}</b>
                                </b-popover>
                                <b-icon class="text-white" icon="chevron-double-down"></b-icon>
                            </b-button>
                        </b-card-header>
                        <b-collapse id="accordion-4" visible accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                                <div class="supplier__listing">
                                    <div v-for="(item, index) in logdata" :key="index">
                                        <div><b>CITY</b><span class="black infogap">{{ item.location }}</span></div>
                                        <div><b>COUNTRY</b><span class="black infogap">{{ item.country }}</span></div>
                                        <div v-if="item.state!='na'"><b>STATE</b><span class="black infogap">{{ item.state }}</span></div>
                                        <div><b>TIME</b><span class="black infogap">{{ item.date }} {{ item.time}}</span></div>
                                        <div><b>MESSAGE</b><span class="black infogap">{{ item.error }}</span></div>
                                        <div>
                                            <!-- <b-button class="gap3" variant="primary" :to="{name:'boundary', params: {location:item.location,country:item.country}}">Map</b-button> -->
                                            <b-button class="gap3" variant="primary"
                                                @click="openInNewTab(item.location, item.country, item.index)">Map</b-button>
                                            <!-- <b-button v-if="userType =='Admin' && rw.action !='null'"  class="gap3" variant="primary" @click="reportinfo(rw, item)">View</b-button> -->
                                            <!-- <b-button v-if="userType =='Admin' && rw.action !='null'"  class="gap3" variant="primary" :to="{name:'report', params: {location:rw.location,country:rw.country,user:rw.user_id,time:rw.time,date:rw.date}}">Logs</b-button> -->
                                            <!-- <b-button class="gap3" variant="primary" :to="{name:'report', params: {location:item.location,country:item.country,user:item.user_id,time:item.time,date:item.date}}">Logs</b-button> -->
                                            <b-button class="gap3" variant="primary"
                                                @click="openInNewTab2(item.location, item.country, item.user_id, item.time, item.date)">Logs</b-button>
                                        </div>
                                    </div>
                                    <br>
                                    <div><b>Total :</b><span class="black infogap">{{ this.insidecount +
                                            this.outsidecount +this.unknowncount
                                            }}</span></div>
                                    <div><b>Green</b><span class="green infogap">{{ this.insidecount }}</span><b> /
                                        </b><b>Red</b><span class="red infogap">{{ this.outsidecount }}</span><b> /
                                        </b><b>Blue</b><span class="blue infogap">{{
                                            this.unknowncount }}</span></div>
                                    <div><b>Suppliers Inside [<span><img src="../../assets/img/pingreen.png"
                                                    alt="map"></span>] <input class="checkHeight" type="checkbox"
                                                :value="true" v-model="testgreen" @change="showgreen"><b
                                                class="checkmark"></b>:</b></div>
                                    <div v-for="(item, index) in plotting_insidecount" :key="index">
                                        <div>
                                            <b>{{ item.supplier }}</b>
                                            <span class="green infogap">{{ item.count }}</span>
                                        </div>
                                    </div>
                                    <div><b>Suppliers Outside [<span><img src="../../assets/img/pin.png"
                                                    alt="map"></span>] <input class="checkHeight" type="checkbox"
                                                :value="true" v-model="testred" @change="showred"><b
                                                class="checkmark"></b>:</b></div>
                                    <div v-for="(item2, index2) in plotting_outsidecount" :key="index2">
                                        <div>
                                            <b>{{ item2.supplier }}</b>
                                            <span class="red infogap">{{ item2.count }}</span>
                                        </div>
                                    </div>
                                    <div v-if="this.unknowncount != 0 && this.plotting_unknowncount != null"><b>Suppliers
                                            Unknown [<span><img src="../../assets/img/pinblue.png" alt="map"></span>]
                                            <input class="checkHeight" type="checkbox" :value="true" v-model="testblue"
                                                @change="showblue"><b class="checkmark"></b>:</b></div>
                                    <div v-for="(item3, index3) in plotting_unknowncount" :key="index3">
                                        <div>
                                            <b>{{ item3.supplier }}</b>
                                            <span class="red infogap">{{ item3.count }}</span>
                                        </div>
                                    </div>
                                </div>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                    <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                            <b-button block v-b-toggle.accordion-1 variant="Success"
                                class="w-100 d-flex justify-content-between bg-under">
                                <h4 class="text-white mb-0"><b-icon icon="geo-alt" class="text-white"></b-icon>
                                    Locations under {{
                                    selectedValue }}</h4>
                                <b-icon id="popover-target-1" icon="exclamation-circle"></b-icon>
                                <b-popover target="popover-target-1" triggers="hover" placement="top">
                                    <!-- <template #title>Popover Title</template> -->
                                    SUPPLIERS INSIDE <b class="city-text">{{ selectedValue }}</b> POLYGON BY LATITUDE
                                    AND LONGITUDE DENOTED
                                    IN GREEN
                                </b-popover>
                                <b-icon class="text-white" icon="chevron-double-down"></b-icon>
                            </b-button>
                        </b-card-header>
                        <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                                <!-- <input class="search1" type="text" v-model="filterText1" placeholder="Hotel Name..." /> -->
                                <div class="supplier__listing">
                                    <input class="search1" type="text" v-model="filterhotel1"
                                        placeholder="Hotel Name..." />
                                    <!-- <button @click="callMethodFromOtherComponent">Test Click</button>               -->
                                    <div class="supplier__listbox" v-for="(item, index) in insideListData" :key="index"
                                        @click="gotolocation1(item, index)">
                                        <p><span class="green">{{ item.hotel_name }}</span></p>
                                        <p><span class="look">[{{ item.supplier }} ({{ item.supplier_code }})]</span>
                                        </p>
                                    </div>
                                </div>
                            </b-card-body>
                        </b-collapse>
                    </b-card>

                    <b-card no-body class="mb-1" v-if="nullBase != 'null'">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                            <b-button block v-b-toggle.accordion-2 variant="Danger"
                                class="w-100 d-flex justify-content-between bg-outside">
                                <h4 v-if="nullBase != 'null'" class="text-white mb-0"><b-icon icon="geo-alt"></b-icon>
                                    Locations outside {{
                                    selectedValue }}</h4>
                                <b-icon id="popover-target-2" icon="exclamation-circle"></b-icon>
                                <b-popover target="popover-target-2" triggers="hover" placement="top">
                                    <!-- <template #title>Popover Title</template> -->
                                    SUPPLIERS OUTSIDE <b class="city-text">{{ selectedValue }}</b> POLYGON BY LATITUDE
                                    AND LONGITUDE DENOTED
                                    IN RED
                                </b-popover>
                                <b-icon class="text-white" icon="chevron-double-down"></b-icon>
                            </b-button>
                        </b-card-header>
                        <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                                <input class="search1" type="text" v-model="filterhotel2" placeholder="Hotel Name..." />
                                <div v-if="nullBase != 'null'" class="supplier__listing">
                                    <div class="supplier__listbox" v-for="(item2, index2) in outsideListData"
                                        :key="index2" @click="gotolocation2(item2, index2)">
                                        <p><span class="red">{{ item2.hotel_name }}</span></p>
                                        <p><span class="look">[{{ item2.supplier }} ({{ item2.supplier_code }})]</span>
                                        </p>
                                        <!-- <p><b>Supplier :</b> <span>{{ item2.supplier }}</span></p> -->
                                    </div>
                                </div>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                    <b-card no-body class="mb-1" v-if="unknownnullBase != 0">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                            <b-button block v-b-toggle.accordion-3 variant="primary"
                                class="w-100 d-flex justify-content-between">
                                <h4 class="text-white mb-0"><b-icon icon="geo-alt"></b-icon> Locations on {{
                                    selectedValue }}</h4>
                                <b-icon id="popover-target-4" icon="exclamation-circle"></b-icon>
                                <b-popover target="popover-target-4" triggers="hover" placement="top">
                                    SUPPLIERS UNKNOWN ON <b class="city-text">{{ selectedValue }}</b> POLYGON DENOTED IN
                                    BLUE
                                </b-popover>
                                <b-icon class="text-white" icon="chevron-double-down"></b-icon>
                            </b-button>
                        </b-card-header>
                        <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                                <input class="search1" type="text" v-model="filterhotel3" placeholder="Hotel Name..." />
                                <div class="supplier__listing">
                                    <div class="supplier__listbox" v-for="(item3, index3) in unknownListData"
                                        :key="index3" @click="gotolocation3(item3, index3)">
                                        <p><span class="blue">{{ item3.hotel_name }}</span></p>
                                        <p><span class="look">[{{ item3.supplier }} ({{ item3.supplier_code }})]</span>
                                        </p>
                                    </div>
                                </div>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                    <!-- <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                            <b-button block v-b-toggle.accordion-5 variant="Success" class="w-100 d-flex justify-content-between bg-info2">
                                <h4 class="text-white mb-0"><b-icon icon="bug" class="text-white"></b-icon><span class="infogap">Debug Panel</span></h4>
                                <b-icon id="popover-target-4" icon="exclamation-circle"></b-icon>
                                <b-popover target="popover-target-4" triggers="hover" placement="top">
                                    CURRENT SEARCH LOGS
                                </b-popover>
                                <b-icon class="text-white" icon="chevron-double-down"></b-icon>
                            </b-button>
                        </b-card-header>
                        <b-collapse id="accordion-5" visible accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                                <div class="supplier__listing">
                                    <div v-for="(item, index) in logdata" :key="index">
                                        <div><b>CITY</b><span class="black infogap">{{ item.location  }}</span></div>
                                        <div><b>COUNTRY</b><span class="black infogap">{{ item.country  }}</span></div>
                                        <div><b>TIME</b><span class="black infogap">{{ item.date   }} {{ item.time }}</span></div>
                                        <div><b>MESSAGE</b><span class="black infogap">{{ item.error  }}</span></div>
                                        <div>
                                            <b-button class="gap3" variant="primary" @click="openInNewTab(item.location,item.country,item.index)">Map</b-button>
                                            <b-button class="gap3" variant="primary" @click="openInNewTab2(item.location,item.country,item.user_id,item.time,item.date)">Logs</b-button>
                                        </div>
                                    </div>
                                </div>
                            </b-card-body>
                        </b-collapse>
                    </b-card> -->

                    <b-button class="button_size d-none" variant="primary" @click="fetchaccuratedata">Search</b-button>
                    <b-button class="button_size d-none" variant="primary" @click="fetchaccuratedata2">Search</b-button>
                    <b-button class="button_size d-none" variant="primary" @click="fetchaccuratedata3">Search</b-button>
                    <div>
                        <!-- <b-button variant="primary" class="destination_save" @click="mapUpdated" v-if="isEditable">Save</b-button> -->
                        <!-- <b-button variant="primary" class="destination_save" @click="deletemapUpdated" v-if="isEditable">Default</b-button> -->
                    </div>

                    <!-- <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                            <b-button block v-b-toggle.accordion-3 variant="info" class="w-100 d-flex justify-content-between"><span>Mumbai</span><b-icon class="text-black" icon="chevron-double-down"></b-icon></b-button>
                        </b-card-header>
                        <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                            <b-card-text>{{ text }}</b-card-text>
                            </b-card-body>
                        </b-collapse>
                        </b-card> -->


                    <!-- <h4><b-icon icon="geo-alt"></b-icon> Locations under {{ selectedValue }}</h4>                    
                    <div class="supplier__listing">                    
                        <div class="supplier__listbox" v-for="item in insideListData" :key="item">
                            <p><b>Hotel : </b> <span>{{ item.hotel_name }}</span></p>
                            <p><b>Supplier :</b> <span>{{ item.supplier }}</span></p>
                        </div>
                    </div>

                    <h4 v-if="nullBase != 'null'" class="mt_25"><b-icon icon="geo-alt"></b-icon> Locations outside {{ selectedValue }}</h4>
                    <div v-if="nullBase != 'null'"  class="supplier__listing">                    
                        <div class="supplier__listbox" v-for="item2 in outsideListData" :key="item2">
                            <p><b>Hotel : </b> <span>{{ item2.hotel_name }}</span></p>
                            <p><b>Supplier :</b> <span>{{ item2.supplier }}</span></p>
                        </div>
                    </div> -->
                </div>
            </div>
            <!-- <GoogleMap @map-updated="(val) => updatedcoordinates=val" ref="GooglemapComponent" :mapdata="insideListData" :gotoclk="gotoclicklocation" :mapdataout="outsideListData" :mapdataexternal="externalListData"  :setpolygonline="setpolygondata"  :edit="isEditable"   v-if="showGoogleMap" /> -->
            <GoogleMap @map-updated="(val) => updatedcoordinates = val" ref="GooglemapComponent" :mapdata="insideListData"
                :gotoclk="gotoclicklocation" :mapdataout="outsideListData" :mapdataexternal="unknownListData"
                :setpolygonline="setpolygondata" :boundingbox="boundingbox_coordinates" :edit="isEditable"
                v-if="showGoogleMap" />

            <div class="text-center" v-if="showloader">
                <img src="/static/img/map.jpg" class="map__img" alt="map">
                <div>
                    <b-spinner variant="primary" :small="true" type="grow"></b-spinner> &nbsp;
                    <b-spinner variant="secondary" :small="true" type="grow"></b-spinner> &nbsp;
                    <b-spinner variant="danger" :small="true" type="grow"></b-spinner> &nbsp;
                    <b-spinner variant="warning" :small="true" type="grow"></b-spinner> &nbsp;
                    <b-spinner variant="success" :small="true" type="grow"></b-spinner>
                </div>
            </div>
            <div class="text-center" v-else>
                <img src="/static/img/map.jpg" class="map__img" alt="map">
                <p class="map__message">Please enter location to get all supplier details.</p>
            </div>
        </div>
        <div id="alertshowhide" class="alert__msg">
            <div class="hed__div">Error!</div>
            <p>{{ alertmsg }}</p>
        </div>
    </div>
</template>

<script setup>
const userType = localStorage.getItem('userType');
</script>

<script>
import axios from '@axios'
import GoogleMap from '../googlemap/GoogleMap.vue'
import {
    BButton, BSpinner,
    BFormInput,
    BCard,
    BCardHeader,
    BCollapse,
    BCardBody,
    BFormSelect,
    BPopover,
} from 'bootstrap-vue'

export default {
    data() {
        return {

            isDropdownOpen: false,
            selectedCountries: [],
            selectedSupplier: [],
            countryData2: ['USA', 'India', 'Canada', 'Germany', 'Australia', 'Insadia', 'Cansaada', 'Germasany', 'Austsaralia'], // Replace with your data source
            selectedOption: "Suppliers Data",
            selectedValue: null,
            selectedValueKM: null,
            selectedCountry: null,
            selecteduser: null,
            min_lat: '',
            max_lat: '',
            min_long: '',
            max_long: '',
            index: '',
            selectedSource:'default',
            showfilter: false,
            showfilter2: true,
            showGoogleMap: false,
            supplierData: null,
            colabListData: null,
            insideListData: null,
            insideListDatabackup: null,
            outsideListData: null,
            outsideListDatabackup: null,
            externalListData: null,
            externalListDatainside: null,
            unknownListData: null,
            unknownListDatabackup: null,
            outersupplierData: null,
            showloader: false,
            logdata: [],
            setpolygondata: [],
            countryData: [],
            SupplierList: [],
            cityData: [],
            alertmsg: null,
            nullBase: null,
            externalnullBase: null,
            gotoclicklocation: null,
            usertype: null,
            isEditable: false,
            polygonmodified: '',
            polygondeleted: '',
            updatedcoordinates: [],
            filterhotel1: null,
            filterhotel2: null,
            filterhotel3: null,
            insidecount: null,
            outsidecount: null,
            supplier_insidecount: null,
            supplier_unknowncount: null,
            supplier_outsidecount: null,
            supplier_externalcount: null,
            supplier_externalinsidecount: null,
            plotting_outsidecount: null,
            plotting_insidecount: null,
            plotting_unknowncount: null,
            unknownnullBase: null,
            showDatalist: false,
            testgreen: true,
            testred: true,
            testblue: true,
            boundingbox_coordinates: [],
            selectedstate: '',
        }
    },
    components: {
        BFormInput,
        BButton,
        GoogleMap,
        BSpinner,
        BCard,
        BCardHeader,
        BCollapse,
        BCardBody,
        BFormSelect,
        BPopover,
    },
    methods: {

        toggleDropdown() {
            this.isDropdownOpen = !this.isDropdownOpen;
        },

        closeDropdown() {
            this.isDropdownOpen = false;
        },
        handleClickOutside(event) {
            const dropdown = this.$el.querySelector('.dropdown');
            if (!dropdown.contains(event.target)) {
                this.closeDropdown();
            }
        },
        toggleSelectAll(event) {
            if (event.target.checked) {
                this.selectedSupplier = [...this.SupplierList];
            } else {
                this.selectedSupplier = [];
            }
            this.getcustompolygonlist();
        },
        mapUpdated() {
            if (this.updatedcoordinates.length == 0) {
                alert("Polygon Co-ordinates not edited")
            } else if (this.updatedcoordinates.length != 0) {
                if (this.selectedstate == '')
                    this.selectedstate = 'na'
                axios.post('modifypolygon', {
                    KM: this.selectedValueKM,
                    city: this.selectedValue,
                    country: this.selectedCountry,
                    state: this.selectedstate,
                    min_lat: this.min_lat,
                    max_lat: this.max_lat,
                    min_long: this.min_long,
                    max_long: this.max_long,
                    coordinates: this.updatedcoordinates,
                }, {
                    // headers: { 'Access-Control-Allow-Origin': '*' }
                }).then(response => {
                    this.polygonmodified = response.data
                    this.updatedcoordinates = null
                    this.$swal({
                        icon: 'success',
                        title: 'Polygon Co-ordinates Saved Successfully',
                        text: this.response,
                    });
                }).catch(error => {
                    console.log("Something went wrong in outersuppliers()")
                })
            }
        },
        openInNewTab(location, country, index) {
            const url = this.$router.resolve({
                name: 'boundary',
                params: { location: location, country: country, index: index }
            }).href;
            window.open(url, '_blank');
        },
        openInNewTab2(location, country, userid, time, date) {
            const url = this.$router.resolve({
                name: 'report',
                params: { location: location, country: country, user: userid, time: time, date: date }
            }).href;
            window.open(url, '_blank');
        },
        deletemapUpdated() {
            axios.post('deletemodifypolygon', {
                city: this.selectedValue,
                country: this.selectedCountry,
            }, {
                // headers: { 'Access-Control-Allow-Origin': '*' }
            }).then(response => {
                // this.outersupplierData=response.data
                this.polygondeleted = response.data
                // this.$router.push(`/boundary/${this.selectedValue}/${this.selectedCountry}`)
                this.$router.push({ name: 'boundary', params: { location: this.selectedValue, country: this.selectedCountry } })
                // window.location.reload();
                // alert("Polygon Co-ordinates Reseted Successfully")
            }).catch(error => {
                console.log("Something went wrong in outersuppliers()")
            })
        },
        gotolocation1: function (item, index) {
            this.$refs.GooglemapComponent.testFun1(item, index)
        },
        gotolocation2: function (item2, index2) {
            this.$refs.GooglemapComponent.testFun2(item2, index2)
        },
        gotolocation3: function (item3, index3) {
            this.$refs.GooglemapComponent.testFun3(item3, index3)
        },
        showgreen: function () {
            const data = this.testgreen
            this.$refs.GooglemapComponent.clickgreen(data)
        },
        showred: function () {
            const data2 = this.testred
            this.$refs.GooglemapComponent.clickred(data2)
        },
        showblue: function () {
            const data3 = this.testblue
            this.$refs.GooglemapComponent.clickblue(data3)
        },
        callMethodFromOtherComponent() {
            GoogleMap.openWindow(); // Call the function from ComponentA
        },
        getcustompolygonlist: function () {
            axios.post('fetchcreatedpolygondestination', {
                country: this.selectedCountry,
            }, {
                // headers: { 'Access-Control-Allow-Origin': '*' }
            }).then(response => {
                this.cityData = response.data
                // console.log("response.data", this.countryData)
            }).catch(error => {
                console.log("Something went wrong in countryGetList()")
            })
        },
        countryGetList: function () {
            axios.get('/country_list', {
                // headers: { 'Access-Control-Allow-Origin': '*' }
            }).then(response => {
                this.countryData = response.data
                // console.log("response.data", this.countryData)
            }).catch(error => {
                console.log("Something went wrong in countryGetList()")
            })
        },
        getsupplierlist: function () {
            axios.get('/destinationsupplierlist', {
                // headers: { 'Access-Control-Allow-Origin': '*' }
            }).then(response => {
                this.SupplierList = response.data
                // console.log("response.data", this.countryData)
            }).catch(error => {
                console.log("Something went wrong in countryGetList()")
            })
        },
        setpolygon: function () {
            if (this.selectedstate == '')
                this.selectedstate = 'na'
            axios.post('/polygon/', {
                state: this.selectedstate,
                city: this.selectedValue,
                country: this.selectedCountry,
                KM: this.selectedValueKM,
                source:this.selectedSource
            }, {
                // headers: { 'Access-Control-Allow-Origin': '*' }
            }).then(response => {
                this.setpolygondata = response.data.coordinates.coordinates
                this.boundingbox_coordinates = response.data.coordinates.bounding_box
                this.min_lat = response.data.coordinates.min_lat
                this.max_lat = response.data.coordinates.max_lat
                this.min_long = response.data.coordinates.min_long
                this.max_long = response.data.coordinates.max_long
                this.logdata = response.data.logdata
                this.index = response.data.coordinates.index
            }).catch(error => {
                console.log("Something went wrong in setpolygon()")
            })
        },
        outersuppliers: function () {
            if (this.selectedstate == '')
                this.selectedstate = 'na'
            // this.externalListData=null
            // this.supplier_externalcount=null
            // this.externalListDatainside=null
            // this.supplier_externalinsidecount=null
            axios.post('/suppliers_datanot/', {
                city: this.selectedValue,
                country: this.selectedCountry,
                data: this.selectedOption,
                supplier: this.selectedSupplier,
                user: this.selecteduser,
                state: this.selectedstate,
                KM: this.selectedValueKM,
                source:this.selectedSource
            }, {
                // headers: { 'Access-Control-Allow-Origin': '*' }
            }).then(response => {
                console.log("polygonview", response.data)
                if (response.data.outside_plotting) {
                    this.externalListDatainside = response.data.outside_plotting.inside
                    // const datatype=typeof this.externalListDatainside;
                    this.externalListData = response.data.outside_plotting.outside  // || response.data.outside_plotting.outside
                    this.supplier_externalinsidecount = response.data.outside_plotting.externalinsidecount
                    this.supplier_externalcount = response.data.outside_plotting.externalcount
                }
                else {
                    this.externalListData = response.data.output
                    this.supplier_externalcount = response.data.externalcount
                }
                this.externalnullBase = this.externalListData[0].city_id;
                return "aala";
            }).catch(error => {
                console.log("Something went wrong in outersuppliers()")
            })
        },

        updateOptions() {
            // Show the datalist when input is focused or has content
            this.showDatalist = this.selectedValue.trim() !== '';
        },
        applymapfilter() {
            this.outsideListData = null,
                this.outsideListDatabackup = null,
                this.externalListData = null,
                this.externalListDatainside = null,
                this.unknownListData = null,
                this.unknownListDatabackup = null,
                this.insidecount = null,
                this.outsidecount = null,
                this.supplier_insidecount = null,
                this.supplier_unknowncoun == null,
                this.supplier_outsidecount = null,
                this.supplier_externalcount = null,
                this.supplier_externalinsidecount = null,
                this.plotting_outsidecount = null,
                this.plotting_insidecount = null,
                this.plotting_unknowncount = null,
                this.insideListData = null,
                this.insideListDatabackup = null,
                this.filterhotel1 = null,
                this.filterhotel2 = null,
                this.filterhotel3 = null,
                this.unknownListData = null
            this.supplier_unknowncount = null
            this.plotting_unknowncount = null
            this.showDatalist = false
            this.showGoogleMap = false
            if (this.selectedstate == '')
                this.selectedstate = 'na'
            // if (this.selectedValue != null && this.selectedCountry !=null){
            this.outersuppliers()
            this.setpolygon()
            this.showloader = true
            this.showfilter = !this.showfilter
            this.showfilter2 = !this.showfilter2
            axios.post('/polygonview/', {
                city: this.selectedValue,
                country: this.selectedCountry,
                data: this.selectedOption,
                supplier: this.selectedSupplier,
                user: this.selecteduser,
                state: this.selectedstate,
                KM: this.selectedValueKM,
                source:this.selectedSource
            }, {
                // headers: { 'Access-Control-Allow-Origin': '*' }
            }).then(response => {
                const obj1 = response.data.map_inout.inside;
                const obj2 = this.externalListDatainside;
                if (obj2 != null) {
                    const concatdata = [];
                    for (const key in obj1) {
                        if (obj1.hasOwnProperty(key)) {
                            concatdata.push(obj1[key]);
                        }
                    }
                    for (const key in obj2) {
                        if (obj2.hasOwnProperty(key)) {
                            if (!concatdata.includes(obj2[key])) {
                                concatdata.push(obj2[key]);
                            }
                        }
                    }
                    this.insideListData = concatdata;
                    this.insideListDatabackup = concatdata;
                } else {
                    this.insideListData = obj1;
                    this.insideListDatabackup = obj1;
                }
                if (response.data.map_inout.outside.length != 0 && this.externalListData != null) {
                    const obj3 = response.data.map_inout.outside;
                    const obj4 = this.externalListData;
                    if (obj3[0].hotel_name == 'null' && obj4[0].hotel_name != 'null') {
                        this.outsideListData = obj4;
                        this.outsideListDatabackup = obj4;
                    } else if (obj3 != null && obj3.length != 1 && obj3[0].hotel_name != 'null' && obj4[0].hotel_name != 'null') {
                        const concatdataexternal = [];
                        for (const key in obj3) {
                            if (obj3.hasOwnProperty(key)) {
                                concatdataexternal.push(obj3[key]);
                            }
                        }
                        for (const key in obj4) {
                            if (obj4.hasOwnProperty(key)) {
                                if (!concatdataexternal.includes(obj4[key])) {
                                    concatdataexternal.push(obj4[key]);
                                }
                            }
                        }
                        this.outsideListData = concatdataexternal;
                        this.outsideListDatabackup = concatdataexternal;
                    } else if (obj4.length == 1 && obj4[0].hotel_name == 'null') {
                        this.outsideListData = obj3;
                        this.outsideListDatabackup = obj3;
                    } else if (obj3.length == 1 && obj3[0].hotel_name == 'null' && obj4.length == 1 && obj4[0].hotel_name == 'null') {
                        this.outsideListData = null;
                        this.outsideListDatabackup = null;
                    }
                } else if (response.data.map_inout.outside.length == 1) {
                    this.outsideListData = this.externalListData;
                    this.outsideListDatabackup = this.externalListData;
                }
                this.supplier_insidecount = response.data.map_inout.insidecount
                this.supplier_outsidecount = response.data.map_inout.outsidecount
                this.supplier_unknowncount = response.data.map_inout.unknowncount
                // console.log("insidecount",this.supplier_insidecount)
                // console.log("outsidecount",this.supplier_outsidecount)
                // console.log("externalcount",this.supplier_externalcount)
                // console.log("externalinside_count",this.supplier_externalinsidecount)
                // console.log("unknow",this.supplier_unknowncount)
                if (this.supplier_externalcount.length != 0 && this.supplier_outsidecount.length != 0) {
                    const array1 = this.supplier_outsidecount;
                    const array2 = this.supplier_externalcount;
                    const combinedArray = array1.concat(array2);
                    const result = {};
                    combinedArray.forEach(item => {
                        const supplier = item.supplier;
                        const count = result[supplier] ? result[supplier].count : 0;
                        if (item.outside_counts !== undefined) {
                            result[supplier] = { 'supplier': supplier, 'count': count + item.outside_counts };
                        } else if (item.external_counts !== undefined) {
                            result[supplier] = { 'supplier': supplier, 'count': count + item.external_counts };
                        } else {
                            result[supplier] = { 'supplier': supplier, 'count': count };
                        }
                    });
                    const resultArray = Object.values(result);
                    this.plotting_outsidecount = resultArray
                } else if (this.supplier_outsidecount.length != 0) {
                    const array1 = this.supplier_outsidecount;
                    const result = {};
                    array1.forEach(item => {
                        const supplier = item.supplier;
                        const count = result[supplier] ? result[supplier].count : 0;
                        if (item.outside_counts) {
                            result[supplier] = { 'supplier': supplier, 'count': count + item.outside_counts };
                        }
                    });
                    const resultArray = Object.values(result);
                    this.plotting_outsidecount = resultArray
                } else if (this.supplier_externalcount.length != 0) {
                    const array2 = this.supplier_externalcount;
                    const result = {};
                    array2.forEach(item => {
                        const supplier = item.supplier;
                        const count = result[supplier] ? result[supplier].count : 0;
                        if (item.external_counts) {
                            result[supplier] = { 'supplier': supplier, 'count': count + item.external_counts };
                        }
                    });
                    const resultArray = Object.values(result);
                    this.plotting_outsidecount = resultArray
                }
                if ((this.supplier_externalinsidecount != null && this.supplier_externalinsidecount.length != 0) && this.supplier_insidecount.length != 0) {
                    console.log("perlin")
                    const array3 = this.supplier_insidecount;
                    const array4 = this.supplier_externalinsidecount;
                    const combinedArray2 = array3.concat(array4);
                    const result2 = {};
                    combinedArray2.forEach(item => {
                        const supplier2 = item.supplier;
                        const count2 = result2[supplier2] ? result2[supplier2].count : 0;
                        if (item.inside_counts !== undefined) {
                            result2[supplier2] = { 'supplier': supplier2, 'count': count2 + item.inside_counts };
                        } else if (item.external_insidecounts !== undefined) {
                            result2[supplier2] = { 'supplier': supplier2, 'count': count2 + item.external_insidecounts };
                        } else {
                            result2[supplier2] = { 'supplier': supplier2, 'count': count2 };
                        }
                    });
                    const resultArray2 = Object.values(result2);
                    this.plotting_insidecount = resultArray2
                } else if (this.supplier_externalinsidecount == null || this.supplier_externalinsidecount.length == 0) {
                    const array3 = this.supplier_insidecount;
                    const result2 = {};
                    array3.forEach(item => {
                        const supplier2 = item.supplier;
                        const count2 = result2[supplier2] ? result2[supplier2].count : 0;
                        if (item.inside_counts) {
                            result2[supplier2] = { 'supplier': supplier2, 'count': count2 + item.inside_counts };
                        }
                    });
                    const resultArray2 = Object.values(result2);
                    this.plotting_insidecount = resultArray2
                }
                if (this.supplier_unknowncount != null && this.supplier_unknowncount.length != 0) {
                    const array5 = this.supplier_unknowncount;
                    const result3 = {};
                    array5.forEach(item => {
                        const supplier3 = item.supplier;
                        const count3 = result3[supplier3] ? result3[supplier3].count : 0;
                        if (item.unknown_counts) {
                            result3[supplier3] = { 'supplier': supplier3, 'count': count3 + item.unknown_counts };
                        }
                    });
                    const resultArray3 = Object.values(result3);
                    this.plotting_unknowncount = resultArray3
                }
                this.unknownListData = response.data.map_inout.unknown
                this.unknownListDatabackup = response.data.map_inout.unknown
                this.unknownnullBase = response.data.map_inout.unknown.length
                this.insidecount = this.insideListData.length
                this.outsidecount = this.outsideListData.length
                this.unknowncount = this.unknownListData.length
                this.nullBase = this.outsideListData[1].city_id
                this.colabListData = response.data.map_inout
                this.showGoogleMap = true
                this.supplierData = response.data
                this.showloader = false
                this.showalertmsg = false
            }).catch(error => {
                this.showloader = true
                if (this.selectedValue != null && this.selectedCountry != null && this.setpolygondata == null) {
                    document.getElementById("alertshowhide").classList.toggle("active__alert")
                    this.alertmsg = "Special Character Not allowed"
                    setTimeout(function () {
                        this.showalertmsg = false
                        document.getElementById("alertshowhide").classList.toggle("active__alert")
                    }, 8000)
                    this.showfilter = !this.showfilter
                    this.showfilter2 = !this.showfilter2
                    this.showGoogleMap = false
                    this.showloader = false
                } else if (this.selectedValue == null && this.selectedCountry == null) {
                    document.getElementById("alertshowhide").classList.toggle("active__alert")
                    this.alertmsg = "City and Country not selected"
                    setTimeout(function () {
                        this.showalertmsg = false
                        document.getElementById("alertshowhide").classList.toggle("active__alert")
                    }, 8000)
                    this.showfilter = !this.showfilter
                    this.showfilter2 = !this.showfilter2
                    this.showGoogleMap = false
                    this.showloader = false
                } else if (this.selectedValue == null) {
                    document.getElementById("alertshowhide").classList.toggle("active__alert")
                    this.alertmsg = "City not selected"
                    setTimeout(function () {
                        this.showalertmsg = false
                        document.getElementById("alertshowhide").classList.toggle("active__alert")
                    }, 8000)
                    this.showfilter = !this.showfilter
                    this.showfilter2 = !this.showfilter2
                    this.showGoogleMap = false
                    this.showloader = false
                } else if (this.selectedCountry == null) {
                    document.getElementById("alertshowhide").classList.toggle("active__alert")
                    this.alertmsg = "Country not selected"
                    setTimeout(function () {
                        this.showalertmsg = false
                        document.getElementById("alertshowhide").classList.toggle("active__alert")
                    }, 8000)
                    this.showfilter = !this.showfilter
                    this.showfilter2 = !this.showfilter2
                    this.showGoogleMap = false
                    this.showloader = false
                } else {
                    document.getElementById("alertshowhide").classList.toggle("active__alert")
                    this.alertmsg = "Suppliers Data Not Found"
                    setTimeout(function () {
                        this.showalertmsg = false
                        document.getElementById("alertshowhide").classList.toggle("active__alert")
                    }, 8000)
                    this.showfilter = !this.showfilter
                    this.showfilter2 = !this.showfilter2
                    this.showGoogleMap = true
                    this.showloader = false
                }
                //  this.selectedValue=''
            })
            // }else if( this.selectedCountry ==null && this.selectedValue  == null){
            //     alert("City and Country not selected")
            // }else if(this.selectedCountry ==null){
            //     alert("Country not selected")
            // }else if (this.selectedValue == null){
            //     alert("City not selected")
            // }
        },
        closelist() {
            this.showfilter2 = !this.showfilter2
            this.showfilter = !this.showfilter
            // this.selectedValue=''
        },
        // handleCheckboxChange(){
        //     console.log("this is working as expected")
        //     if (this.DataFrom1 == true){
        //         console.log("yess")
        //         this.hide1=false
        //     }else if ( this.DataFrom2 == true){
        //         console.log("noo")
        //         this.hide2=false
        //     }
        //     // else{
        //     //     hide1=true
        //     //     hide2=true
        //     // }
        // }
    },
    mounted() {
        this.countryGetList();
        this.getsupplierlist();
        document.addEventListener('click', this.handleClickOutside);
    },
    beforeUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    },
    computed: {
        isAllSelected() {
            return this.selectedSupplier.length === this.SupplierList.length;
        },
        fetchaccuratedata() {
            this.insideListData = null
            this.insideListData = this.insideListDatabackup
            if (this.filterhotel1) {
                const datafiltered = Object.fromEntries(
                    Object.entries(this.insideListData)
                        .filter(([key, value]) => value.hotel_name.toLowerCase().includes(this.filterhotel1.toLowerCase()))
                    // .filter(([key,value]) => value.hotel_name.toLowerCase() === this.filterhotel1.toLowerCase())
                );
                this.insideListData = datafiltered
                return this.insideListData

            } else {
                this.insideListData = this.insideListDatabackup
                return this.insideListData;
            }
        },
        fetchaccuratedata2() {
            this.outsideListData = null
            this.outsideListData = this.outsideListDatabackup
            if (this.filterhotel2) {
                const datafiltered = Object.fromEntries(
                    Object.entries(this.outsideListData)
                        .filter(([key, value]) => value.hotel_name.toLowerCase().includes(this.filterhotel2.toLowerCase()))
                );
                this.outsideListData = datafiltered
                return this.outsideListData

            } else {
                this.outsideListData = this.outsideListDatabackup
                return this.outsideListData;
            }
        },
        fetchaccuratedata3() {
            this.unknownListData = null
            this.unknownListData = this.unknownListDatabackup
            if (this.filterhotel3) {
                const datafiltered = Object.fromEntries(
                    Object.entries(this.unknownListData)
                        .filter(([key, value]) => value.hotel_name.toLowerCase().includes(this.filterhotel3.toLowerCase()))
                );
                this.unknownListData = datafiltered
                return this.unknownListData

            } else {
                this.unknownListData = this.unknownListDatabackup
                return this.unknownListData;
            }
        },
    }

}

</script>

<style>
/* .sidebar__right .navbar__section {
    padding: 0px;    
}
    .sidebar__right .navbar__section:before {
    height: 0 !important;
}
.sidebar__right .navbar__section h3 {
    display: none;
}
.sidebar__right .navbar__section p {
    display: none;
}
.sidebar__right .navbar__section .other__navpart {
    display: inline-flex;
    position: absolute;
    right: 20px;
    top: 15px;
} */
select {
    width: 100%;
    height: 50px;
    border-radius: 6px;
    font-size: 15px;
    padding: 0 15px;
    border: 2px solid #378ad6;
    background: transparent;
    color: #000;
    outline: none;
}

.infogap {
    margin-left: 10px;
}

input:focus~label,
input:valid~label,
select:focus~label,
select:valid~label {
    top: 0;
    left: 15px;
    font-size: 13px;
    padding: 0 2px;
    background: #fff;
}

.checkHeight {
    height: 12px !important;
}

.margBase {
    margin: 0 0 1rem 0;
}

.checkHeight input:checked~.checkmark {
    background-color: #2196F3 !important;
}

.input-field input,
.input-field select {
    width: 100%;
    height: 50px;
    border-radius: 6px;
    font-size: 15px;
    padding: 0 15px;
    border: 2px solid #378ad6;
    background: transparent;
    color: #000;
    outline: none;
}

.checkmark {
    color: rgb(29, 81, 224);
    margin-left: 10px;
}

.bg-under {
    background-color: #00ae08 !important;
}

.bg-outside {
    background-color: #c81b00 !important;
}

.bg-info {
    background-color: blue !important;
}

.bg-info2 {
    border-color: rgb(119, 17, 167) !important;
    background-color: rgb(119, 17, 167) !important;
}

.city-text {
    text-transform: uppercase;
}

.green {
    color: #00ae08;
}

.red {
    color: #c81b00;
}

.blue {
    color: blue
}

input[type="checkbox"] {
    transform: scale(1.5);
    /* Adjust the scale factor to make it larger */
}

.destination_save {
    width: 300px !important;
    margin-left: 10px;
    margin-right: 10px;
}

.look {
    color: black;
}

.search1 {
    height: 35px;
    width: 290px;
}

.red {
    color: #c81b00;
}

.black {
    color: black;
}


.input-field {
    position: relative !important;
    width: 100% !important;
}

.boxBread {
    position: absolute !important;
    top: 0 !important;
    left: 15px !important;
    transform: translateY(-50%) !important;
    color: #000 !important;
    font-size: 15px !important;
    pointer-events: none !important;
    transition: .3s !important;
    background: white !important;
    z-index: 9 !important;
    font-size: 14px !important;
}

.dropdown {
    position: relative !important;
}

.checksocks {
    width: 16px !important;
    height: 16px !important;
}

.dropdown-toggle {
    overflow: hidden !important;
    border: 1px solid #ccc !important;
    padding: 8px !important;
    cursor: pointer !important;
    width: 100% !important;
    height: 50px !important;
    border-radius: 6px !important;
    font-size: 15px !important;
    padding: 10px 15px !important;
    border: 2px solid #378ad6 !important;
    background: transparent !important;
    color: #000 !important;
    outline: none !important;
}

.dropdown.open .dropdown-menu {
    display: block !important;
}

.newator {
    display: flex !important;
    justify-content: flex-start !important;
}

.basicname {
    margin: 0 !important;
}

.dropdown-menu {
    display: none !important;
    position: absolute !important;
    background: white !important;
    border: 1px solid #ccc !important;
    width: 100% !important;
    max-height: 200px !important;
    overflow-y: auto !important;
    z-index: 10 !important;
    list-style: none !important;
    margin: 0 !important;
    padding: 0 !important;
}

.dropdown-menu li {
    padding: 8px !important;
    cursor: pointer !important;
}

.dropdown-menu li input {
    margin-right: 8px !important;
}

.arrow {
    float: right !important;
    border: solid black !important;
    border-width: 0 2px 2px 0 !important;
    display: inline-block !important;
    padding: 3px !important;
    transform: rotate(45deg) !important;
    margin-top: 8px !important;
}
</style>

<!-- <b-card no-body class="mb-1" v-if="externalnullBase != 'null'">
    <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-3 variant="primary" class="w-100 d-flex justify-content-between">
            <h4 v-if="externalnullBase != 'null'" class="text-white mb-0" ><b-icon icon="geo-alt"></b-icon> Locations near {{ selectedValue }}</h4>
            <b-icon id="popover-target-3" icon="exclamation-circle"></b-icon>
            <b-popover target="popover-target-3" triggers="hover" placement="top">
                SUPPLIERS NEARBY <b class="city-text">{{ selectedValue }}</b> POLYGON BY CITY DENOTED IN BLUE
            </b-popover>
            <b-icon class="text-white" icon="chevron-double-down"></b-icon>
        </b-button>
    </b-card-header>
    <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
        <b-card-body>
            <div v-if="externalnullBase != 'null'"  class="supplier__listing">                    
                <div class="supplier__listbox" v-for="(item3, index3) in externalListData" :key="index3"  @click="gotolocation3(item3,index3)">
                    <p><b>Hotel : </b> <span>{{ item3.hotel_name }} <span class="look">({{ item3.supplier }})</span></span></p>
                </div>
            </div>
        </b-card-body>
    </b-collapse>
</b-card> -->