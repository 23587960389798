<template>
  <div>
    <div>{{this.$store.getters.getApikey}}</div>
    <gmap-map
      ref="gmap"
      :center="center"
      :options="mapStyle"
      :zoom="11" class="map_full">
      <gmap-marker        
        v-for="(gmp, index) in locations"
        :visible="showMarkers1"
        :key="index"
        :position="gmp"
        :clickable="true"
        :draggable="false"
        :icon="{ url: require('../../assets/img/pingreen.png')}"
        @click="openWindow(index)"
      ></gmap-marker>
      <gmap-marker     
        v-for="(gmp, index) in locationsout"
        :visible="showMarkers2"
        :key="index"
        :position="gmp"
        :clickable="true"
        :draggable="false"
        :icon="{ url: require('../../assets/img/pin.png')}"
        @click="openWindowout(index)"
      ></gmap-marker>
      <gmap-marker        
          v-for="(gmp, index) in locationsexternal"
          :visible="showMarkers3"
          :key="index"
          :position="gmp"
          :clickable="true"
          :draggable="false"
          :icon="{ url: require('../../assets/img/pinblue.png')}"
          @click="openWindowexternal(index)"
        ></gmap-marker>
      <gmap-info-window 
            @closeclick="infoWindow.open=false" 
            :opened="infoWindow.open" 
            :position="infoWindow.position"
            :options="{
              pixelOffset: {
                width: 0,
                height: -35
              }
            }"
        >
            <div v-html="infoWindow.template"></div>
          </gmap-info-window> 
            <gmap-info-window 
            @closeclick="infoWindowout.open=false" 
            :opened="infoWindowout.open" 
            :position="infoWindowout.position"
            :options="{
              pixelOffset: {
                width: 0,
                height: -35
              }
            }"
        >
            <div v-html="infoWindowout.template"></div>
        </gmap-info-window> 
        <gmap-info-window 
              @closeclick="infoWindowexternal.open=false" 
              :opened="infoWindowexternal.open" 
              :position="infoWindowexternal.position"
              :options="{
                pixelOffset: {
                  width: 0,
                  height: -35
                }
              }"
          >
              <div v-html="infoWindowexternal.template"></div>
          </gmap-info-window> 
      <!-- <gmap-polygon  v-if="paths.length > 0" :paths="paths" :editable="Editable" @paths_changed="updateEdited($event)"></gmap-polygon> -->
      <!-- <gmap-polygon :paths="paths" :editable="Editable" @paths_changed="updateEdited($event)"></gmap-polygon> -->
      <gmap-polygon v-for="(object, index) in setpolygonline" :key="index" :paths="object.paths" :editable="Editable" @paths_changed="updateEdited($event)"></gmap-polygon>
      <gmap-polygon :paths="boundingbox" :options="{strokeColor: '#0000FF',strokeOpacity: 1.0,strokeWeight: 2,strokePattern: [2, 4],fillColor: '#0000FF',fillOpacity: 0.2}"></gmap-polygon>
    </gmap-map>
    
  </div>
</template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/vue/2.6.11/vue.js"></script>
<script src="https://cdn.jsdelivr.net/npm/gmap-vue@1.2.2/dist/gmap-vue.min.js"></script>
<!-- <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyCq8M1FYn1IUbs-4XH-H9RbhueFy6PDQBc&libraries=drawing"></script> -->

<script async defer :src="apiconcat"></script>

<script>

import { 
BIcon
} from 'bootstrap-vue'
export default {
  components: {
    BIcon
  },
  data() {
    return {
      ratingIMG:{
        url: require('../../assets/img/star1.png'),
      },
      apiconcat:null,
      center: { 
          lat: this.setpolygonline[0].lat, 
          lng: this.setpolygonline[0].lng,
      },

      // center: { 
      //     lat: this.mapdata[0].latitude, 
      //     lng: this.mapdata[0].longitude,
      // },      
      locations: [],
      locationsout: [],
        locationsexternal: [],
      paths: [],
      paths2: [],
      paths3:[],
      Editable:false,
      showMarkers1:true,
      showMarkers2:true,
      showMarkers3:true,
    //   markerOptions: {
    //   url: require('../../assets/img/pin.png'),
    //   size: {width: 60, height: 90, f: 'px', b: 'px',},
    //   scaledSize: {width: 30, height: 45, f: 'px', b: 'px',},
    // },
    infoWindow: {
          position: {lat: 0, lng: 0},
          open: false,
          template: ''
        },
    infoWindowout: {
          position: {lat: 0, lng: 0},
          open: false,
          template: ''
        },
          infoWindowexternal: {
            position: {lat: 0, lng: 0},
            open: false,
            template: ''
          },
      mapStyle: {
        fullscreenControl: false, 
        mapTypeControl: false,
        zoomControl: false,
        scaleControl: false,
        streetViewControl: false,
    // styles: [
    //     {elementType: 'geometry', stylers: [{color: '#e5e4e0'}]},
    //     {elementType: 'labels.text.stroke', stylers: [{color: '#e5e4e0'}]},
    //     {elementType: 'labels.text.fill', stylers: [{color: '#000'}]},
    //     {
    //         featureType: 'administrative.locality',
    //         elementType: 'labels.text.fill',
    //         stylers: [{color: '#526e84'}]
    //     },
    //     {
    //         featureType: 'poi',
    //         elementType: 'labels.text.fill',
    //         stylers: [{color: '#000'}]
    //     },
    //     {
    //         featureType: 'poi.park',
    //         elementType: 'geometry',
    //         stylers: [{color: '#b8dc9c'}]
    //     },
    //     {
    //         featureType: 'poi.park',
    //         elementType: 'labels.text.fill',
    //         stylers: [{color: '#000'}]
    //     },
    //     {
    //         featureType: 'road',
    //         elementType: 'geometry',
    //         stylers: [{color: '#f9a664'}]
    //     },
    //     {
    //         featureType: 'road',
    //         elementType: 'geometry.stroke',
    //         stylers: [{color: '#bf9343'}]
    //     },
    //     {
    //         featureType: 'road',
    //         elementType: 'labels.text.fill',
    //         stylers: [{color: '#6f5126'}]
    //     },
    //     {
    //         featureType: 'road.highway',
    //         elementType: 'geometry',
    //         stylers: [{color: '#f7fabf'}]
    //     },
    //     {
    //         featureType: 'road.highway',
    //         elementType: 'geometry.stroke',
    //         stylers: [{color: '#b1b864'}]
    //     },
    //     {
    //         featureType: 'road.highway',
    //         elementType: 'labels.text.fill',
    //         stylers: [{color: '#818181'}]
    //     },
    //     {
    //         featureType: 'transit',
    //         elementType: 'geometry',
    //         stylers: [{color: '#3d4e9e'}]
    //     },
    //     {
    //         featureType: 'transit.station',
    //         elementType: 'labels.text.fill',
    //         stylers: [{color: '#fff'}]
    //     },
    //     {
    //         featureType: 'water',
    //         elementType: 'geometry',
    //         stylers: [{color: '#8accec'}]
    //     },
    //     {
    //         featureType: 'water',
    //         elementType: 'labels.text.fill',
    //         stylers: [{color: '#fff'}]
    //     },
    //     {
    //         featureType: 'water',
    //         elementType: 'labels.text.stroke',
    //         stylers: [{color: '#fff'}]
    //     }
    // ]
  }
    }
  },

  props: {
    mapdata:[Object, Array],
    mapdataout:[Object, Array],
    mapdataexternal:[Object, Array],
    setpolygonline:[Object, Array, Number],
    boundingbox:[Object, Array, Number],
    gotoclk:[Object, Array, Number],
    edit:[Boolean]
  },
 
  mounted() {
    this.apiconcat='https://maps.googleapis.com/maps/api/js?key='+this.$store.getters.getApikey+'&libraries=drawing';
    this.setLocationLatLng()
    console.log("getloc",this.gotoclk)
    if(this.gotoclk!=null){
      openWindow(this.gotoclk)
    }  
    console.log("first Inside");
    this.$refs.gmap.$on('gmap-ready', () => {
      console.log("second Inside");
      this.setupDrawingManager();
      console.log("third Inside");
  });
    
  },
 
  methods: {
    setupDrawingManager(){
      console.log("Inside");
      const drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: null, // Set to null initially, so no shape is being drawn
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [
          google.maps.drawing.OverlayType.MARKER,
          google.maps.drawing.OverlayType.POLYGON,
        ],
      },
      markerOptions: {
        icon: {
          url: require('../../assets/img/pin.png'), // Your marker icon
        },
      },
      polygonOptions: {
        fillColor: '#f7fabf', // Your polygon fill color
        strokeColor: '#b1b864', // Your polygon stroke color
        editable: true,
      },
    });

    drawingManager.setMap(this.$refs.gmap.$mapPromise); // Set the map for the DrawingManager

    // Add event listeners for drawing completion
    google.maps.event.addListener(drawingManager, 'overlaycomplete', (event) => {
      if (event.type === google.maps.drawing.OverlayType.MARKER) {
        // Handle marker overlay
        // You can access the marker position using event.overlay.getPosition()
      } else if (event.type === google.maps.drawing.OverlayType.POLYGON) {
        // Handle polygon overlay
        // You can access the polygon paths using event.overlay.getPath().getArray()
        const paths = event.overlay.getPath().getArray();
        // Do something with the paths...
      }
    });
    
    console.log("exit");
    },
    setLocationLatLng: function() {
      console.log(this.setpolygonline,"test",this.setpolygonline[0].lat)
  this.locations = [];
  this.locationsout = [];
  this.locationsexternal = [];
  
  navigator.geolocation.getCurrentPosition(geolocation => {
    this.center = {
      lat: geolocation.coords.latitude,
      lng: geolocation.coords.longitude
    };
  });
    // for (let i = 0; i < this.setpolygonline.length; i++) {
    //       // Iterate through the inner array
    //   // for (let j = 0; j < this.setpolygonline[i].length; j++) {
    //   //   // Extract lat and lng from each object and push to the new array
    //   //   const { lat, lng } = this.setpolygonline[i][j];
    //   //   console.log(lat, lng )
    //   //   this.paths.push({ lat, lng });
    //   // }
    // }


    this.setpolygonline.forEach((arrayitm, arridx)=>{
    console.log("testbasic", arrayitm)
    
    // this.paths = arrayitm
    // if(arridx == 0){
    //   arrayitm.forEach((item, id)=>{
    //     this.paths.push(item);
    //     // console.log("item", this.paths)
    // });

    // }else{
    //   arrayitm.forEach((item, id)=>{
    //     this.paths2.push(item);
    //     // console.log("item2", this.paths2)
    // });

    // }
    // console.log("this.paths2", this.paths , this.paths2)

      // if(arridx == 0){
      //   this.paths = arrayitm;
      // }
      // arrayitm.forEach((latlong)=>{
      //   this.paths = arrayitm
      //   console.log(arridx, "lat_ long", latlong)
      // })
    });
    console.log("this.setpolygonline",this.setpolygonline)
    this.center = {
      lat :this.setpolygonline[0].paths[0].lat,
      lng :this.setpolygonline[0].paths[0].lng,
    }

    console.log("this.paths",this.paths)

  // this.paths = this.setpolygonline;
  this.Editable = this.edit;

  // Populate this.locations array
  this.mapdata.forEach(element => {
    this.locations.push({
      lat: element.latitude,
      lng: element.longitude
    });
  });

  // Populate this.locationsout array if it exists and is not empty
  if (this.mapdataout && this.mapdataout.length > 0) {
    this.mapdataout.forEach(element => {
      this.locationsout.push({
        lat: element.latitude,
        lng: element.longitude
      });
    });
  }

  // Populate this.locationsexternal array if it exists and is not empty
  if (this.mapdataexternal && this.mapdataexternal.length > 0) {
    this.mapdataexternal.forEach(element => {
      this.locationsexternal.push({
        lat: element.latitude,
        lng: element.longitude
      });
    });
  }
},

openWindow(index) {
    const rating = this.mapdata[index].rating; 
    let stars = '';
    
    if (rating == 'sc') {
        stars = 'SC Rating'; // Fallback for zero rating
    } else {
        const starCount = Math.floor(rating); // Ensure integer for iteration
        for (let i = 0; i < starCount; i++) {
            stars += `<img src="${this.ratingIMG.url}" alt="star" style="width: auto; height: 16px; margin-right: 0px;" />`;
        }
    }

    this.infoWindow.position = { lat: this.mapdata[index].latitude, lng: this.mapdata[index].longitude };
    this.infoWindow.template = `
        <p><b>Hotel : </b> ${this.mapdata[index].hotel_name}</p>
        <p><b>Supplier : </b> ${this.mapdata[index].supplier} (${this.mapdata[index].supplier_code})</p>
        <p><b>Rating : </b> ${stars}</p>
        <p><b>City : </b> ${this.mapdata[index].city_name}</p>
        <p><b>Latitude : </b> ${this.mapdata[index].latitude}</p>
        <p><b>Longitude : </b> ${this.mapdata[index].longitude}</p>
        <p><b>Center to KM : </b> ${this.mapdata[index].centre_km}</p>`;
    this.infoWindow.open = true;
},
openWindowout(index) {
    const rating = this.mapdataout[index].rating;
    const fullStars = Math.floor(rating); // Full stars
    const hasHalfStar = rating % 1 !== 0; // Check for half star
    let stars = '';

    if (rating == 'sc') {
        stars = 'SC Rating'; // Fallback for zero rating
    } else {
        // Add full stars
        for (let i = 0; i < fullStars; i++) {
            stars += `<img src="${this.ratingIMG.url}" alt="star" style="width: auto; height: 16px; margin-right: 0px;" />`;
        }
        // Add half star if applicable
        if (hasHalfStar) {
            stars += `<img src="${this.halfStarIMG.url}" alt="half star" style="width: auto; height: 16px; margin-right: 0px;" />`;
        }
    }

    this.infoWindowout.position = { lat: this.mapdataout[index].latitude, lng: this.mapdataout[index].longitude };
    this.infoWindowout.template = `
        <p><b>Hotel : </b> ${this.mapdataout[index].hotel_name}</p>
        <p><b>Supplier : </b> ${this.mapdataout[index].supplier} (${this.mapdataout[index].supplier_code})</p>
        <p><b>Rating : </b> ${this.mapdataout[index].rating}</p>
        <p><b>City : </b> ${this.mapdataout[index].city_name}</p>
        <p><b>Latitude : </b> ${this.mapdataout[index].latitude}</p>
        <p><b>Longitude : </b> ${this.mapdataout[index].longitude}</p>
        <p><b>Center to KM : </b> ${this.mapdataout[index].centre_km}</p>`;
    this.infoWindowout.open = true;
},

openWindowexternal(index) {
    const rating = this.mapdataexternal[index].rating;
    const fullStars = Math.floor(rating); // Full stars
    const hasHalfStar = rating % 1 !== 0; // Check for half star
    let stars = '';

    if (rating == 'sc') {
        stars = 'SC Rating'; // Fallback for zero rating
    } else {
        // Add full stars
        for (let i = 0; i < fullStars; i++) {
            stars += `<img src="${this.ratingIMG.url}" alt="star" style="width: auto; height: 16px; margin-right: 0px;" />`;
        }
    }

    this.infoWindowexternal.position = { lat: this.mapdataexternal[index].latitude, lng: this.mapdataexternal[index].longitude };
    this.infoWindowexternal.template = `
        <p><b>Hotel : </b> ${this.mapdataexternal[index].hotel_name}</p>
        <p><b>Supplier : </b> ${this.mapdataexternal[index].supplier} (${this.mapdataexternal[index].supplier_code})</p>
        <p><b>Rating : </b> ${this.mapdataexternal[index].rating}</p>
        <p><b>City : </b> ${this.mapdataexternal[index].city_name}</p>
        <p><b>Latitude : </b> ${this.mapdataexternal[index].latitude}</p>
        <p><b>Longitude : </b> ${this.mapdataexternal[index].longitude}</p>
        <p><b>Center to KM : </b> ${this.mapdataexternal[index].centre_km}</p>`;
    this.infoWindowexternal.open = true;
},


    testFun1: function(item,index){
        this.openWindow(index)
    },
    testFun2: function(item2,index2){
        this.openWindowout(index2)
    },
    testFun3: function(item3,index3){
        this.openWindowexternal(index3)
    },
    clickgreen:function(data){
      this.showMarkers1=data
    },
    clickred:function(data2){
      this.showMarkers2=data2
    },
    clickblue:function(data3){
      this.showMarkers3=data3
    },
      updateEdited(mvcArray) {
      console.log("function is called")
      let paths = [];
      for (let i=0; i<mvcArray.getLength(); i++) {
        let path = [];
        for (let j=0; j<mvcArray.getAt(i).getLength(); j++) {
          let point = mvcArray.getAt(i).getAt(j);
          path.push({lat: point.lat(), lng: point.lng()});
        }
        paths.push(path);
      }
      const updatedcoordinates = paths;
      this.$emit('map-updated',updatedcoordinates[0] )
    },
  }
}
</script>
