<template>
  <div>
    <div v-if="loading" class="loader"></div>
        <h3 align="center" v-if="loading" >Loading Data...</h3>
        <div v-if="!loading">
        <div>
            <b-row>
            <b-col lg="12">
                <h3 align="center" v-if="userType !='Admin'">LOGS</h3>
                <h3 align="center" v-if="userType =='Admin'">POINTS LOGS</h3>
            <!-- {{ userList }} -->
           <input class="gap" type="date" v-model="filterText1" placeholder="Date..." />
            <!-- <input class="gap" type="text" v-model="filterText2" placeholder="User..." /> -->
            <b-form-select v-if="userType =='Admin'" class="gap2" type="text" v-model="filterText2">
              <b-form-select-option value="" disabled>Select Username</b-form-select-option>
              <b-form-select-option 
                v-for="(user, index) in userList" 
                :key="index" 
                :value="user.encrypted_username">
                {{ user.decrypted_username }}
              </b-form-select-option>
            </b-form-select>
            <input class="gap" type="text" v-model="filterText3" placeholder="Locations..." />
            <input class="gap" type="text" v-model="filterText4" placeholder="Country..." />
            <b-form-select v-if="userType !='Admin'" class="gap2" type="text" v-model="filterText5" id="statusDropdown">
              <b-form-select-option value="" disabled>Select Status</b-form-select-option>
              <b-form-select-option value="Alert">Alert</b-form-select-option>
              <b-form-select-option value="Failed">Failed</b-form-select-option>
              <b-form-select-option value="Request">Request</b-form-select-option>
              <b-form-select-option value="Success">Success</b-form-select-option>
            </b-form-select>
            <b-button class="gap4" variant="primary" @click="fetchLogs2()">Submit</b-button>
            <b-button class="gap4" variant="primary" v-if="!isDownloading" @click="downloadExcel()">Download</b-button>
            <h2 class="text-center p-5" v-if="nodata"> No Data Found</h2>
            <div v-else>
              
            <div class="pagination mt-3">
              <!-- Previous Page Button -->
              <a href="#" @click.prevent="previousPage" :class="{ disabled: currentPage === 1 }">&laquo;</a>

              <!-- Dynamic Page Numbers -->
              <a v-for="page in visiblePages" :key="page" :class="{ active: currentPage === page }"
                @click.prevent="goToPage(page)">
                {{ page }}
              </a>

              <!-- Next Page Button -->
              <a href="#" @click.prevent="nextPage" :class="{ disabled: currentPage === totalPages }">&raquo;</a>
            </div>
            <b-spinner variant="primary" class="dosyun" v-if="isLoading"></b-spinner>
            <table v-else>
              <thead>
                <tr>
                  <th v-for="(col) in activelogs.columns" :key="col.id">
                    {{ col}}
                    <b-icon v-if="col == 'response_status'" id="link-button" icon="exclamation-circle"></b-icon>
                    <b-popover v-if="col == 'response_status'" target="link-button" triggers="hover">
                      <p><b class="info"><u>200</u> : </b>Indicates Success</p>
                      <p><b class="info"><u>403</u> : </b>Indicates Error</p>
                    </b-popover>
                    <b-icon v-if="col == 'action'" id="link-button2" icon="exclamation-circle"></b-icon>
                    <b-popover v-if="col == 'action'" target="link-button2" triggers="hover">
                      <p><b><u>Open Map Button</u> : </b> Clicking on Open Map Button redirect user to another page
                        where the user can save polygon coordinates according to him selecting the OSM Response.</p>
                    </b-popover>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(rw, item) in activelogs.data" :key="item">
                  <!-- <td v-if="userType =='Admin'">{{ rw.user_id }}</td>
                  <td v-if="userType =='Admin'">{{ rw.username }}</td> -->
                  <td>{{ rw.date }}<span class="info2"></span>{{ rw.time }}</td>
                  <td v-if="userType =='Admin'">{{ rw.username }}</td>
                  <td>{{ rw.country }}</td>
                  <td>{{ rw.location }}<span class="info2" v-if="rw.state !='na' && rw.state !=' '">({{ rw.state }})</span></td>
                  <!-- <td>{{ rw.date }}<span class="info"></span>{{ rw.time }}</td> -->
                  <!-- <td>{{ rw.datetime }}</td> -->
                  <!-- <td v-if="userType !='Admin' && userType !='Client' " >{{ rw.date }}</td> -->
                  <!-- <td>{{ rw.response_status }}</td> -->
                  <td ><span :class="[rw.status == 'Success' || rw.status == 'Action Taken'? 'greentxt' : 'redtxt']">({{ rw.status.toLowerCase() }})</span> {{ rw.error.toLowerCase() }}</td>
                  <!-- <td>{{ rw.error }}</td>
                  <td >{{ rw.status }}</td> -->
                  <td v-if="userType !='Admin'">{{ rw.flag }}</td>
                  <!-- <td v-if="userType !='Admin'">{{ rw.flag }}</td> -->
                  <!-- <td v-if="userType !='Admin'">{{ rw.flag }}</td> -->
                  <!-- <td >{{ rw.flag }}</td> -->
                  <td>
                      <!-- <b-link class="h6 me-2 d-inline-block" v-if="rw.action =='None'" :to="`https://www.google.com/maps/place/${encodeURIComponent(rw.location)},+${encodeURIComponent(rw.country)}`" target="_blank" title="Map"><b-icon icon="geo-alt"></b-icon></b-link> -->
                      <a class="h6 me-2 d-inline-block" v-if="rw.action =='None' && rw.state !='na'" :href="`https://www.google.com/maps/place/${encodeURIComponent(rw.location)},+${encodeURIComponent(rw.state)}+${encodeURIComponent(rw.country)}`" target="_blank" title="Map"><b-icon icon="geo-alt"></b-icon></a>
                      <a class="h6 me-2 d-inline-block" v-if="rw.action =='None' && rw.state =='na'" :href="`https://www.google.com/maps/place/${encodeURIComponent(rw.location)},+${encodeURIComponent(rw.country)}`" target="_blank" title="Map"><b-icon icon="geo-alt"></b-icon></a>
                      <b-link title="info" class="h6 me-2 d-inline-block" :to="{name:'custommap', params: {city:rw.location,country:rw.country,state:rw.state,index:'null',source:rw.source,userid:rw.user_id}}" target="_blank" rel="noopener noreferrer"><b-icon icon="pentagon"></b-icon></b-link>
                      <b-link title="info" class="h6 me-2 d-inline-block" :to="{name:'destinationcustom', params: {city:rw.location,state:rw.state,country:rw.country,supplier:rw.suppliers,username:rw.encrypted_username,source:rw.source,userid:rw.user_id}}" target="_blank" rel="noopener noreferrer"><b-icon icon="geo"></b-icon></b-link>
                      <b-link class="h6 me-2 d-inline-block" v-if="rw.action !='null'" @click="reportInfo(rw.location,rw.country,rw.user_id,rw.date,rw.time,rw.suppliers,rw.requestid)" title="Logs"><b-icon icon="file-text"></b-icon></b-link>
                      <!-- <b-link class="h6 me-2 d-inline-block" v-if="rw.action !='null'" title="info" :to="{name:'hotellist', params: {location:rw.location,country:rw.country,user:rw.user_id,username:rw.encrypted_username,state:rw.state}}"><b-icon icon="bounding-box-circles"></b-icon></b-link> -->
                      <b-link v-if="rw.action !='null' && rw.flag =='API'" title="info" class="h6" :to="{name:'hotellist', params: {location:rw.location,country:rw.country,user:rw.user_id,username:rw.encrypted_username,state:rw.state,supplier:rw.suppliers}}" target="_blank" rel="noopener noreferrer"><b-icon icon="info-circle"></b-icon></b-link>
                      <!-- <b-button v-if="rw.action =='None'" class="gap3" variant="primary" @click="fetchData(rw, item)">Action</b-button> -->
                      <!-- <b-button v-if="userType =='Admin' && rw.action !='null'"  class="gap3" variant="primary" @click="reportinfo(rw, item)">View</b-button> -->
                      <!-- <b-button v-if="userType =='Admin' && rw.action !='null'"  class="gap3" variant="primary" :to="{name:'report', params: {location:rw.location,country:rw.country,user:rw.user_id,time:rw.time,date:rw.date}}">Logs</b-button> -->
                      <!-- <b-button v-if="rw.action !='null'"  class="gap3" variant="primary" :to="{name:'report', params: {location:rw.location,country:rw.country,user:rw.user_id,time:rw.time,date:rw.date}}">Logs</b-button> -->
                      <!-- <b-button v-if="rw.action !='null'"  class="gap3" variant="primary" :to="{name:'report', params: {location:rw.location,country:rw.country,user:rw.user_id,time:rw.time,date:rw.date}}">Custom</b-button> -->
                      <p v-if="rw.action =='null'">{{ rw.action }}</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- <div>
              <b-button variant="primary" @click="previousPage" :disabled="currentPage === 1" class="mx-2 py-1">Previous</b-button>
              <span>{{ currentPage }} / {{ totalPages }}</span>
              <b-button variant="primary" @click="nextPage" :disabled="currentPage === totalPages"  class="mx-2 py-1">Next</b-button>
            </div> -->


            <div class="pagination">
              <!-- Previous Page Button -->
              <a href="#" @click.prevent="previousPage" :class="{ disabled: currentPage === 1 }">&laquo;</a>

              <!-- Dynamic Page Numbers -->
              <a v-for="page in visiblePages" :key="page" :class="{ active: currentPage === page }"
                @click.prevent="goToPage(page)">
                {{ page }}
              </a>

              <!-- Next Page Button -->
              <a href="#" @click.prevent="nextPage" :class="{ disabled: currentPage === totalPages }">&raquo;</a>
            </div>

            <div class="pagination-container">
              <!-- Render page numbers dynamically -->


              <!-- Go to specific page -->
              <input type="number" v-model.number="goToPageInput" class="mx-2 hhsna" :min="1" :max="totalPages"
                placeholder="Page #" />
              <p class="parabase">
                out of {{ totalPages }}
              </p>
              <b-button variant="success" class="hsggaf" @click="goToSpecificPage"
                :disabled="!isValidPage(goToPageInput)">
                Go
              </b-button>
            </div>

            </div>

            <!-- <b-table :items="paginatedData" >
              <template #cell(action)="data">
                <b-button v-if="rw.action =='None'" class="gap3" variant="primary" :to="{name:'boundary', params: {location:data.item.location,country:data.item.country}}">Action</b-button>
                    <b-button v-if="userType =='Admin' && data.item.action !='null'"  class="gap3" variant="primary" @click="reportinfo(rw, item)">View</b-button>
                    <b-button v-if="userType =='Admin' && data.item.action !='null'"  class="gap3" variant="primary" :to="{name:'report', params: {location:data.item.location,country:data.item.country,user:data.item.user_id,time:data.item.time,date:data.item.date}}">View</b-button>
                    <p v-if="data.item.action =='null'">{{ data.item.action }}</p>
              </template>
</b-table> -->
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>

import * as XLSX from "xlsx";
import axios from '@axios'
import JSZip from 'jszip';
import { BRow, BCol, BSpinner, BButton, BFormSelect, BFormSelectOption, BPopover, BIcon } from "bootstrap-vue"
export default {
  components: {
    BRow, BSpinner,
    BCol, BButton,
    BFormSelect, BFormSelectOption, BPopover, BIcon
  },
  data() {
    return {
      isLoading: false,
      verificationstatus: null,
      loading: true,
      activelogs: null,
      status: null,
      userName: '',
      city: '',
      country: '',
      date: '',
      time: '',
      error: '',
      status2: '',
      suppliers: '',
      flag: '',
      search: '',
      response: '',
      response_status: '',
      end_time: '',
      time_taken: '',
      source: '',
      osm_response: '',
      jsonData1: {
        Title: 'Search Logs',
        Time: '',
        Date: '',
        Search: '',
      },
      jsonData2: {
        Title: 'Response Logs',
        Time: '',
        Date: '',
        Time_Taken: '',
        Response: '',
      },
      jsonData3: {
        Response: ''
      },
      filterText1: '',
      filterText2: '',
      filterText3: '',
      filterText4: '',
      filterText5: '',
      currentPage: 1,
      itemsPerPage: 200, // Number of items per page
      // currentPage: 1, // Current active page
      // totalPages: 50, // Replace with the actual total number of pages
      goToPageInput: null, // Input for "Go to Page"
      userList:null,
      nodata:false,
      isDownloading:false,
    }
  },
  methods: {
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchLogs();
      }
    },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.fetchLogs();
      }
    },

    fetchLogs() {
      this.isLoading = true; // Show loading spinner
      console.log("this.currentPage",this.currentPage);
      console.log("this.filterText1",this.filterText1);
      console.log("this.filterText2",this.filterText2);
      console.log("this.filterText3",this.filterText3);
      console.log("this.filterText4",this.filterText4);
      console.log("this.filterText5",this.filterText5);
      
      axios.post('logs', { page: this.currentPage,
        date: this.filterText1,
        username: this.filterText2,
        location: this.filterText3,
        country: this.filterText4,
        status: this.filterText5,
       })
        .then(response => {
          console.log("response.data.error",response.data)
          if (response.data.error == 'No data found.') {
            this.nodata=true;
          } else {
            this.nodata=false;
            this.activelogs = response.data.logs;
            console.log(response.data, "fetchLogs", this.activelogs)
            this.totalPages = response.data.logs.pagination.total_pages
            this.isLoading = false;
            this.loading = false;
          }
          

          // this.totalPages = Math.ceil(response.data.totalLogs / this.itemsPerPage); // Update total pages
        })
        .catch(error => {
          console.error("Error fetching logs:", error);
          this.$buefy.toast.open({
            message: "Failed to fetch logs. Please try again later.",
            type: "is-danger",
          });
          this.isLoading = false;
          this.loading = false;
        })
        .finally(() => {
          this.isLoading = false; // Hide loading spinner
          this.loading = false;

        });
    },
    fetchLogs2() {
      this.isLoading = true; // Show loading spinner
      console.log("this.currentPage",this.currentPage);
      console.log("this.filterText1",this.filterText1);
      console.log("this.filterText2",this.filterText2);
      console.log("this.filterText3",this.filterText3);
      console.log("this.filterText4",this.filterText4);
      console.log("this.filterText5",this.filterText5);
      this.currentPage=1
      axios.post('logs', { page: this.currentPage,
        date: this.filterText1,
        username: this.filterText2,
        location: this.filterText3,
        country: this.filterText4,
        status: this.filterText5,
       })
        .then(response => {
          console.log("response.data.error",response.data)
          if (response.data.error == 'No data found.') {
            this.nodata=true;
          } else {
            this.nodata=false;
            
            this.activelogs = response.data.logs;
            console.log(response.data, "fetchLogs", this.activelogs)
            this.totalPages = response.data.logs.pagination.total_pages
            this.isLoading = false;
            this.loading = false;
          }



          

          // this.totalPages = Math.ceil(response.data.totalLogs / this.itemsPerPage); // Update total pages
        })
        .catch(error => {
          console.error("Error fetching logs:", error);
          this.$buefy.toast.open({
            message: "Failed to fetch logs. Please try again later.",
            type: "is-danger",
          });
          this.isLoading = false;
          this.loading = false;
        })
        .finally(() => {
          this.isLoading = false; // Hide loading spinner
          this.loading = false;

        });
    },

downloadExcel() {
  this.isDownloading = false; // Show loading spinner
  axios.post('downloadlogs/', {
  page: this.currentPage,
  date: this.filterText1,
  username: this.filterText2,
  location: this.filterText3,
  country: this.filterText4,
  status: this.filterText5,
  })
  .then(response => {
    console.log("response.data.error", response.data);
    if (response.data.error === 'No data found.') {
      this.isDownloading = true;
    } else {
      this.isDownloading = false;

      // Extract logs data
      const logs = response.data.logs.data;

      if (logs.length > 0) {
        // Prepare data for Excel
        const headers = response.data.logs.columns;
        const rows = logs.map(log => {
          const row = {};
          headers.forEach(header => {
            row[header] = log[header] || "";
          });
          return row;
        });

        // Create a worksheet
        const worksheet = XLSX.utils.json_to_sheet(rows, { header: headers });

        // Create a workbook
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Logs");

        // Generate Excel file and trigger download
        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
        const blob = new Blob([excelBuffer], { type: "application/octet-stream" });

        // Create a download link
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "logs.xlsx");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

      // this.activelogs = response.data.logs;
      // console.log(response.data, "fetchLogs", this.activelogs);
      // this.totalPages = response.data.logs.pagination?.total_pages || 1;
    }
  })
  .catch(error => {
    console.error("Error fetching logs:", error);
    this.$buefy.toast.open({
      message: "Failed to fetch logs. Please try again later.",
      type: "is-danger",
    });
  })
  .finally(() => {
    this.isDownloading = false; // Hide loading spinner
  });
  },
    goToPage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
        this.fetchLogs();
      }
    },
    goToSpecificPage() {
      if (this.isValidPage(this.goToPageInput)) {
        this.currentPage = this.goToPageInput;
        this.goToPageInput = null; // Clear the input
        this.fetchLogs();
      }
    },
    isValidPage(page) {
      return page >= 1 && page <= this.totalPages;
    },
    downloadData1() {
      console.log("download data 1")
      const jsonData1 = {
        Date: this.date,
        Time: this.time,
        Search: this.search,
      };
      return JSON.stringify(jsonData1, null, 2);
    },
    downloadData2() {
      console.log("download data 2")
      const jsonData2 = {
        Date: this.date,
        Start_Time: this.time,
        End_Time: this.end_time,
        Time_Taken: this.time_taken,
        Response: this.response,
      }
      return JSON.stringify(jsonData2, null, 2);
    },
    osmresponse() {
      this.jsonData3.Response = this.osm_response
      const jsonData = JSON.stringify(this.jsonData3, null, 2); // Convert JSON object to a nicely formatted JSON string
      this.downloadTextFile(jsonData, `${this.userName}_osmresponse_${this.city}_${this.country}.json`);

    },
    downloadTextFile(text, filename) {
      const blob = new Blob([text], { type: "application/json" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      a.click();
      window.URL.revokeObjectURL(url);
    },
    downloadZip() {
      console.log("downloadzip function is called")
      const zip = new JSZip();

      // Adding JSON data to zip
      zip.file(`${this.userName}_search_log_${this.city}_${this.date}.json`, this.downloadData1());
      zip.file(`${this.userName}_response_log_${this.city}_${this.date}.json`, this.downloadData2());

      // Generate the zip file
      console.log("function is called for zip")
      zip.generateAsync({ type: "blob" })
        .then(content => {
          // Create a link element
          const link = document.createElement("a");
          link.href = URL.createObjectURL(content);
          link.download = `${this.userName}_logs_${this.city}_${this.date}.zip`;

          // Trigger the download
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
    },
    saveverifiedcity(location, country, state, verification) {
      for (let index = 0; index < this.activelogs.data.length; index++) {
        if (this.activelogs.data[index].location == location) {
          this.activelogs.data[index].verification = 1;
        }

      }
      axios.post('saveverifiedcities', {
        location: location,
        country: country,
        state: state,
      }, {
        // { headers: { 'Access-Control-Allow-Origin': '*' }}
      }).then(response => {
        this.verificationstatus = response.data
        // this.Error()
      }).catch(error => {
        console.log("Something went wrong in reportInfo()")
      })
    },
    handleModalClose() {
      this.filterText1 = ''
      this.filterText2 = ''
      this.filterText3 = ''
      this.filterText4 = ''
      this.filterText5 = ''
      this.filterText6 = ''
    },
    reportInfo(location, country, user, date, time, suppliers, requestid) {
      axios.post('searchreport', {
        requestid: requestid,
        location: location,
        country: country,
        user: user,
        time: time,
        date: date,
        suppliers: suppliers,
      }, {
        // { headers: { 'Access-Control-Allow-Origin': '*' }}
      }).then(response => {
        this.userName = response.data.report.username
        this.city = response.data.report.city
        this.country = response.data.report.country
        this.date = response.data.report.date
        this.time = response.data.report.time
        this.error = response.data.report.error
        this.status2 = response.data.report.status
        this.suppliers = response.data.report.suppliers
        this.flag = response.data.report.flag
        this.search = response.data.report.search
        this.response = response.data.report.response
        this.response_status = response.data.report.response_status
        this.end_time = response.data.report.end_time
        this.time_taken = response.data.report.time_taken
        this.source = response.data.report.source
        this.osm_response = response.data.report.osm_response
        this.downloadZip()
      }).catch(error => {
        console.log("Something went wrong in reportInfo()")
      })
    },
    Error: function () {
      axios.post('logs', {
        // headers: { 'Access-Control-Allow-Origin': '*' }
      }).then(response => {
        this.activelogs = response.data.logs;
        console.log(typeof (response.data.logs.pagination.total_pages), 'this.activelogs', response.data)
        this.totalPages = response.data.logs.pagination.total_pages
        // alert(this.totalPages)
      }).catch(error => {
        // console.log("Something went wrong in countryGetList()")
      })
    },
    // reportinfo(row, index){
    //   this.Reportdata={
    //     location:row.location,
    //     country: row.country,
    //     user: row.user_id,
    //     time:row.time,
    //     date:row.date,

    //   }
    //   this.$router.push({ name: 'report', params:{ id:this.Reportdata}});
    // },
    Refreshcount: function () {
      axios.post('testrestart', {
        // headers: { 'Access-Control-Allow-Origin': '*' }
      }).then(response => {
        const resetdata = 0;
        this.$store.commit('setCounter', resetdata)
        this.status = response.data
      }).catch(error => {
        // console.log("Something went wrong in countryGetList()")
      })
    },
  },
  mounted() {
    // this.Error()
    this.Refreshcount()
    this.Error()
    
    axios.get('usernames2/').then(response => {
        console.log("response.data",response.data);
        this.userList = response.data;
      }).catch(error => {
        // console.log("Something went wrong in countryGetList()")
      })

    this.loading = false;
    // setTimeout(() => {
    //   this.Error()
    //   this.loading = false; // Set loading state to false after the data is fetched or processed
    // }, 1000); // Simulating a delay (2 seconds) for data fetching, replace this with your actual data fetching logic

  },
  computed: {


    visiblePages() {
      const pagesToShow = 10;
      let startPage = this.currentPage - Math.floor(pagesToShow / 2);
      let endPage = this.currentPage + Math.floor(pagesToShow / 2);

      // Ensure the start and end pages are within the valid range
      if (startPage < 1) {
        startPage = 1;
        endPage = pagesToShow;
      }
      if (endPage > this.totalPages) {
        endPage = this.totalPages;
        startPage = this.totalPages - pagesToShow + 1;
      }

      // Generate the array of visible pages
      const visiblePages = [];
      for (let i = startPage; i <= endPage; i++) {
        visiblePages.push(i);
      }

      return visiblePages;
    },
    pagesToShow() {
      const totalVisiblePages = 10; // Number of pages to display
      const half = Math.floor(totalVisiblePages / 2);

      let start = Math.max(1, this.currentPage - half);
      let end = Math.min(this.totalPages, this.currentPage + half);

      // Ensure a full set of 10 pages is shown when possible
      if (end - start + 1 < totalVisiblePages) {
        if (start === 1) {
          end = Math.min(this.totalPages, start + totalVisiblePages - 1);
        } else if (end === this.totalPages) {
          start = Math.max(1, end - totalVisiblePages + 1);
        }
      }

      // Create an array of page numbers
      return Array.from({ length: end - start + 1 }, (_, i) => start + i);
    },
    filteredData() {
      if (this.filterText3 && this.filterText2 && this.filterText1 && this.filterText4 && this.filterText5) {
        const filterTextlower1 = Number(this.filterText3);
        const filterTextLower2 = this.filterText1.toLowerCase();
        const filterTextLower3 = this.filterText2.toLowerCase();
        const filterTextLower4 = this.filterText4.toLowerCase();
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item =>
          (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 &&
          item.location.toLowerCase().includes(filterTextLower2) &&
          item.country.toLowerCase().includes(filterTextLower3) &&
          item.status.toLowerCase().includes(filterTextLower4) &&
          item.date.includes(filterDatelower5)
        );
      } else if (this.filterText1 && this.filterText2 && this.filterText4 && this.filterText5) {
        const filterTextLower2 = this.filterText1.toLowerCase();
        const filterTextLower3 = this.filterText2.toLowerCase();
        const filterTextLower4 = this.filterText4.toLowerCase();
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item =>
          item.location.toLowerCase().includes(filterTextLower2) &&
          item.country.toLowerCase().includes(filterTextLower3) &&
          item.status.toLowerCase().includes(filterTextLower4) &&
          item.date.includes(filterDatelower5)
        );
      } else if (this.filterText3 && this.filterText2 && this.filterText4 && this.filterText5) {
        const filterTextlower1 = Number(this.filterText3);
        const filterTextLower3 = this.filterText2.toLowerCase();
        const filterTextLower4 = this.filterText4.toLowerCase();
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item =>
          (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 &&
          item.country.toLowerCase().includes(filterTextLower3) &&
          item.status.toLowerCase().includes(filterTextLower4) &&
          item.date.includes(filterDatelower5)
        );
      } else if (this.filterText6 && this.filterText1 && this.filterText2 && this.filterText5) {
        const filterTextlower6 = this.filterText6.toLowerCase();
        const filterTextLower1 = this.filterText1.toLowerCase();
        const filterTextLower2 = this.filterText2.toLowerCase();
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item =>
          item.username.toLowerCase().includes(filterTextlower6) &&
          item.location.toLowerCase().includes(filterTextLower1) &&
          item.country.toLowerCase().includes(filterTextLower2) &&
          item.date.includes(filterDatelower5)
        );
      } else if (this.filterText6 && this.filterText2 && this.filterText5) {
        const filterTextlower6 = this.filterText6.toLowerCase();
        const filterTextLower2 = this.filterText2.toLowerCase();
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item =>
          item.username.toLowerCase().includes(filterTextlower6) &&
          item.country.toLowerCase().includes(filterTextLower2) &&
          item.date.includes(filterDatelower5)
        );
      } else if (this.filterText6 && this.filterText1 && this.filterText2) {
        const filterTextlower6 = this.filterText6.toLowerCase();
        const filterTextLower1 = this.filterText1.toLowerCase();
        const filterTextLower2 = this.filterText2.toLowerCase();
        return this.activelogs.data.filter(item =>
          item.username.toLowerCase().includes(filterTextlower6) &&
          item.location.toLowerCase().includes(filterTextLower1) &&
          item.country.toLowerCase().includes(filterTextLower2)
        );
      } else if (this.filterText6 && this.filterText1 && this.filterText5) {
        const filterTextlower6 = this.filterText6.toLowerCase();
        const filterTextLower1 = this.filterText1.toLowerCase();
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item =>
          item.username.toLowerCase().includes(filterTextlower6) &&
          item.location.toLowerCase().includes(filterTextLower1) &&
          item.date.includes(filterDatelower5)
        );
      } else if (this.filterText6 && this.filterText2) {
        const filterTextlower6 = this.filterText6.toLowerCase();
        const filterTextLower2 = this.filterText2.toLowerCase();
        return this.activelogs.data.filter(item =>
          item.username.toLowerCase().includes(filterTextlower6) &&
          item.country.toLowerCase().includes(filterTextLower2)
        );
      } else if (this.filterText6 && this.filterText1) {
        const filterTextlower6 = this.filterText6.toLowerCase();
        const filterTextLower1 = this.filterText1.toLowerCase();
        return this.activelogs.data.filter(item =>
          item.username.toLowerCase().includes(filterTextlower6) &&
          item.location.toLowerCase().includes(filterTextLower1)
        );
      } else if (this.filterText6 && this.filterText5) {
        const filterTextlower6 = this.filterText6.toLowerCase();
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item =>
          item.username.toLowerCase().includes(filterTextlower6) &&
          item.date.includes(filterDatelower5)
        );
      } else if (this.filterText3 && this.filterText1 && this.filterText4 && this.filterText5) {
        const filterTextlower1 = Number(this.filterText3);
        const filterTextLower2 = this.filterText1.toLowerCase();
        const filterTextLower4 = this.filterText4.toLowerCase();
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item =>
          (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 &&
          item.location.toLowerCase().includes(filterTextLower2) &&
          item.status.toLowerCase().includes(filterTextLower4) &&
          item.date.includes(filterDatelower5)
        );
      } else if (this.filterText3 && this.filterText1 && this.filterText2 && this.filterText5) {
        const filterTextlower1 = Number(this.filterText3);
        const filterTextLower2 = this.filterText1.toLowerCase();
        const filterTextLower3 = this.filterText2.toLowerCase();
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item =>
          (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 &&
          item.location.toLowerCase().includes(filterTextLower2) &&
          item.country.toLowerCase().includes(filterTextLower3) &&
          item.date.includes(filterDatelower5)
        );
      } else if (this.filterText3 && this.filterText1 && this.filterText2 && this.filterText4) {
        const filterTextlower1 = Number(this.filterText3);
        const filterTextLower2 = this.filterText1.toLowerCase();
        const filterTextLower3 = this.filterText2.toLowerCase();
        const filterTextLower4 = this.filterText4.toLowerCase();
        return this.activelogs.data.filter(item =>
          (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 &&
          item.location.toLowerCase().includes(filterTextLower2) &&
          item.country.toLowerCase().includes(filterTextLower3) &&
          item.status.toLowerCase().includes(filterTextLower4)
        );
      } else if (this.filterText1 && this.filterText4 && this.filterText5) {
        const filterTextLower2 = this.filterText1.toLowerCase();
        const filterTextLower4 = this.filterText4.toLowerCase();
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item =>
          item.location.toLowerCase().includes(filterTextLower2) &&
          item.status.toLowerCase().includes(filterTextLower4) &&
          item.date.includes(filterDatelower5)
        );
      } else if (this.filterText2 && this.filterText4 && this.filterText5) {
        const filterTextLower3 = this.filterText2.toLowerCase();
        const filterTextLower4 = this.filterText4.toLowerCase();
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item =>
          item.country.toLowerCase().includes(filterTextLower3) &&
          item.status.toLowerCase().includes(filterTextLower4) &&
          item.date.includes(filterDatelower5)
        );
      } else if (this.filterText1 && this.filterText2 && this.filterText5) {
        const filterTextLower2 = this.filterText1.toLowerCase();
        const filterTextLower3 = this.filterText2.toLowerCase();
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item =>
          item.location.toLowerCase().includes(filterTextLower2) &&
          item.country.toLowerCase().includes(filterTextLower3) &&
          item.date.includes(filterDatelower5)
        );
      } else if (this.filterText1 && this.filterText2 && this.filterText4) {
        const filterTextLower2 = this.filterText1.toLowerCase();
        const filterTextLower3 = this.filterText2.toLowerCase();
        const filterTextLower4 = this.filterText4.toLowerCase();
        return this.activelogs.data.filter(item =>
          item.location.toLowerCase().includes(filterTextLower2) &&
          item.country.toLowerCase().includes(filterTextLower3) &&
          item.status.toLowerCase().includes(filterTextLower4)
        );
      } else if (this.filterText3 && this.filterText4 && this.filterText5) {
        const filterTextlower1 = Number(this.filterText3);
        const filterTextLower4 = this.filterText4.toLowerCase();
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item =>
          (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 &&
          item.status.toLowerCase().includes(filterTextLower4) &&
          item.date.includes(filterDatelower5)
        );
      } else if (this.filterText3 && this.filterText2 && this.filterText5) {
        const filterTextlower1 = Number(this.filterText3);
        const filterTextLower3 = this.filterText2.toLowerCase();
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item =>
          (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 &&
          item.country.toLowerCase().includes(filterTextLower3) &&
          item.date.includes(filterDatelower5)
        );
      } else if (this.filterText3 && this.filterText2 && this.filterText4) {
        const filterTextlower1 = Number(this.filterText3);
        const filterTextLower3 = this.filterText2.toLowerCase();
        const filterTextLower4 = this.filterText4.toLowerCase();
        return this.activelogs.data.filter(item =>
          (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 &&
          item.country.toLowerCase().includes(filterTextLower3) &&
          item.status.toLowerCase().includes(filterTextLower4)
        );
      } else if (this.filterText3 && this.filterText1 && this.filterText5) {
        const filterTextlower1 = Number(this.filterText3);
        const filterTextLower2 = this.filterText1.toLowerCase();
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item =>
          (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 &&
          item.location.toLowerCase().includes(filterTextLower2) &&
          item.date.includes(filterDatelower5)
        );
      } else if (this.filterText3 && this.filterText1 && this.filterText4) {
        const filterTextlower1 = Number(this.filterText3);
        const filterTextLower2 = this.filterText1.toLowerCase();
        const filterTextLower4 = this.filterText4.toLowerCase();
        return this.activelogs.data.filter(item =>
          (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 &&
          item.location.toLowerCase().includes(filterTextLower2) &&
          item.status.toLowerCase().includes(filterTextLower4)
        );
      } else if (this.filterText3 && this.filterText1 && this.filterText2) {
        const filterTextlower1 = Number(this.filterText3);
        const filterTextLower2 = this.filterText1.toLowerCase();
        const filterTextLower3 = this.filterText2.toLowerCase();
        return this.activelogs.data.filter(item =>
          (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 &&
          item.location.toLowerCase().includes(filterTextLower2) &&
          item.country.toLowerCase().includes(filterTextLower3)
        );
      } else if (this.filterText3 && this.filterText1) {
        const filterTextlower1 = Number(this.filterText3);
        const filterTextLower2 = this.filterText1.toLowerCase();
        return this.activelogs.data.filter(item =>
          (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 &&
          item.location.toLowerCase().includes(filterTextLower2)
        );
      } else if (this.filterText3 && this.filterText2) {
        const filterTextlower1 = Number(this.filterText3);
        const filterTextLower3 = this.filterText2.toLowerCase();
        return this.activelogs.data.filter(item =>
          (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 &&
          item.country.toLowerCase().includes(filterTextLower3)
        );
      } else if (this.filterText3 && this.filterText4) {
        const filterTextlower1 = Number(this.filterText3);
        const filterTextLower4 = this.filterText4.toLowerCase();
        return this.activelogs.data.filter(item =>
          (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 &&
          item.status.toLowerCase().includes(filterTextLower4)
        );
      } else if (this.filterText3 && this.filterText5) {
        const filterTextlower1 = Number(this.filterText3);
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item =>
          (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 &&
          item.date.includes(filterDatelower5)
        );
      } else if (this.filterText1 && this.filterText2) {
        const filterTextLower2 = this.filterText1.toLowerCase();
        const filterTextLower3 = this.filterText2.toLowerCase();
        return this.activelogs.data.filter(item =>
          item.location.toLowerCase().includes(filterTextLower2) &&
          item.country.toLowerCase().includes(filterTextLower3)
        );
      } else if (this.filterText1 && this.filterText4) {
        const filterTextLower2 = this.filterText1.toLowerCase();
        const filterTextLower4 = this.filterText4.toLowerCase();
        return this.activelogs.data.filter(item =>
          item.location.toLowerCase().includes(filterTextLower2) &&
          item.status.toLowerCase().includes(filterTextLower4)
        );
      } else if (this.filterText1 && this.filterText5) {
        const filterTextLower2 = this.filterText1.toLowerCase();
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item =>
          item.location.toLowerCase().includes(filterTextLower2) &&
          item.date.includes(filterDatelower5)
        );
      } else if (this.filterText2 && this.filterText4) {
        const filterTextLower3 = this.filterText2.toLowerCase();
        const filterTextLower4 = this.filterText4.toLowerCase();
        return this.activelogs.data.filter(item =>
          item.country.toLowerCase().includes(filterTextLower3) &&
          item.status.toLowerCase().includes(filterTextLower4)
        );
      } else if (this.filterText2 && this.filterText5) {
        const filterTextLower3 = this.filterText2.toLowerCase();
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item =>
          item.country.toLowerCase().includes(filterTextLower3) &&
          item.date.includes(filterDatelower5)
        );
      } else if (this.filterText4 && this.filterText5) {
        const filterTextLower4 = this.filterText4.toLowerCase();
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item =>
          item.status.toLowerCase().includes(filterTextLower4) &&
          item.date.includes(filterDatelower5)
        );
      } else if (this.filterText3) {
        const filterTextlower1 = Number(this.filterText3);
        return this.activelogs.data.filter(item =>
          (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1
        );
      } else if (this.filterText1) {
        const filterTextLower2 = this.filterText1.toLowerCase();
        return this.activelogs.data.filter(item =>
          item.location.toLowerCase().includes(filterTextLower2)
        );
      } else if (this.filterText2) {
        const filterTextLower3 = this.filterText2.toLowerCase();
        return this.activelogs.data.filter(item =>
          item.country.toLowerCase().includes(filterTextLower3)
        );
      } else if (this.filterText4) {
        const filterTextLower4 = this.filterText4.toLowerCase();
        return this.activelogs.data.filter(item =>
          item.status.toLowerCase().includes(filterTextLower4)
        );
      }
      else if (this.filterText6) {
        const filterTextLower6 = this.filterText6.toLowerCase();
        return this.activelogs.data.filter(item =>
          item.username.toLowerCase().includes(filterTextLower6)
        );
      } else if (this.filterText5) {
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item =>
          item.date.includes(filterDatelower5)
        );
      } else {
        console.log("this is else condition")
        return this.activelogs.data;
      }
    },
    // totalPages() {
    //   return Math.ceil(this.filteredData.length / this.itemsPerPage);
    // },
    paginatedData() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.filteredData.slice(startIndex, endIndex);
    },
    getColumnClass(index) {
      // Add a specific class to the second column
      return index === 3 ? "modified-column" : "";
    },
    getColumnContent(col, index) {
      // Modify the content of the second column
      if (index === 3) {
        return `<i class="fas fa-user"></i> ${col}`;
      } else {
        return col;
      }
    },
  },
}
</script>
<style>
.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 75px;
  height: 75px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
  margin-top: 200px;
}

.verify {
  margin-left: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.gap {
  margin-right: 5px;
  border-color: black;
  width: 250px;
}

.gap2 {
  margin-top: 10px;
  border-color: black;
  width: 15%;
  background: white;
}

.gap3 {
  margin-left: 5px;
  margin-top: 5px;
}

.gap4 {
  margin-left: 20px;
  width: 100px;
  height: 48px;
}

.gap5 {
  margin-left: 20px;
  width: 120px;
  height: 48px;
}

.info {
  padding-left: 20px;
}

.info2 {
  padding-left: 5px;
}

.span_space {
  margin-right: 10px;
}

.active-page {
  background-color: #007bff;
  color: white;
  font-weight: bold;
}

.hhsna {
  width: 10%;
  height: 34px;
  padding: 10px;
  border: thin solid #ddd;

}

.hsggaf {
  height: 34px;
  width: 60px;
}

.pagination-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.parabase {
  margin: 0;
  padding: 0 10px 0 0;
  font-size: 12px;
  color: #606060;
}

.pagination {
  display: inline-block;
  justify-content: center;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
}

.pagination a.active {
  background-color: #4CAF50;
  color: white;
  border-radius: 5px;
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
  border-radius: 5px;
}

.dosyun {
  display: block !important;
  margin: 5rem auto;
  padding: 1rem;

}
</style>
<script setup>
const userType = localStorage.getItem('userType');
</script>
